import { QueryClient, useQueryClient } from '@tanstack/react-query';
import fetchIntercept from 'fetch-intercept';
import { useEffect } from 'react';
import { Socket } from 'socket.io-client';

import { APIResponse } from 'api';
import { VehicleSummary } from 'models';

const mutationMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

interface MessageContent {
  eventType: EventType;
  tenantId?: string;
  inventoryId?: string;
  year?: number;
  make?: string;
  model?: string;
  vin?: string;
  stockNumber?: string;
}
export interface Message extends MessageContent {
  room: string;
  event: string;
}

export enum EventType {
  assigneeChange = 'assigneeChange',
  stepChange = 'stepChange',
  update = 'update',
  create = 'create',
}

let interceptorSocket: Socket | undefined;
let interceptorTenantId: string | undefined;
let interceptorSharedStepsMap: any | undefined;
let interceptorDealerVendorTenantIds: string[] | undefined;
let interceptorQueryClient: QueryClient;

const getInventoryId = (url: string) => {
  const inventoryPattern =
    /(recon|inventory)\/([A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12})/;
  const inventoryMatches = url.match(inventoryPattern) ?? [];

  if (inventoryMatches && inventoryMatches?.length === 3) {
    return inventoryMatches[2];
  }
};

const getIsTagUpdate = (url: string, inventoryId?: string) => {
  const tagsPattern = /\/(tags)/;
  const tagsMatches = url.match(tagsPattern) ?? [];
  return !!inventoryId && tagsMatches && tagsMatches?.length === 2;
};

const getEventType = (url: string, method?: string) => {
  if (
    method === 'POST' &&
    (url.endsWith('recon') || url.endsWith('inventory'))
  ) {
    return EventType.create;
  }

  const assigneeChangePattern = /(workflow\/step\/user)/;
  const assigneeChangeMatches = url.match(assigneeChangePattern) ?? [];
  if (assigneeChangeMatches?.length === 2) {
    return EventType.assigneeChange;
  }

  const stepChangePattern = /(workflow\/step)/;
  const stepChangeMatches = url.match(stepChangePattern) ?? [];
  if (stepChangeMatches?.length === 2) {
    return EventType.stepChange;
  }

  return EventType.update;
};

const getVehicleSummaryFromPagedQueryCacheByQueryKey = (
  queryKey: string[],
  inventoryId: string
) => {
  let vehicleSummary: VehicleSummary | undefined;
  let queryData: any = interceptorQueryClient.getQueriesData({
    queryKey,
    type: 'active',
  })?.[0];
  const pages: any = queryData?.[1]?.pages;
  pages?.find(({ data: vehicles }: { data: VehicleSummary[] }) => {
    vehicleSummary = vehicles?.find(
      (vehicle) => vehicle.vehicleCard.id === inventoryId
    );
    return !!vehicleSummary;
  });
  return vehicleSummary;
};

const getVehicleSummaryFromQueryCacheByQueryKey = (
  queryKey: string[],
  inventoryId: string
) => {
  let queryData: any = interceptorQueryClient.getQueriesData({
    queryKey,
    type: 'active',
  })?.[0];
  const vehicles: VehicleSummary[] = queryData?.[1]?.data;
  return vehicles?.find((vehicle) => vehicle.vehicleCard.id === inventoryId);
};

export const getVehicleSummaryFromQueryCacheByInventoryId = (
  inventoryId: string
) => {
  let vehicleSummary: VehicleSummary | undefined;
  let queryData: any;

  vehicleSummary = getVehicleSummaryFromPagedQueryCacheByQueryKey(
    ['/inventory'],
    inventoryId
  );

  if (!vehicleSummary) {
    vehicleSummary = getVehicleSummaryFromQueryCacheByQueryKey(
      ['/inventory/invoicedInventories'],
      inventoryId
    );
  }

  if (!vehicleSummary) {
    vehicleSummary = getVehicleSummaryFromQueryCacheByQueryKey(
      ['/vendor-inventory'],
      inventoryId
    );
  }

  if (!vehicleSummary) {
    queryData = interceptorQueryClient.getQueryData([
      `/inventory/${inventoryId}`,
    ]);
    vehicleSummary = queryData?.data;
  }

  return vehicleSummary;
};

const getInventoryDetailsFromVehicleSummary = (
  vehicleSummary: VehicleSummary | undefined
) => {
  return {
    inventoryId: vehicleSummary?.vehicleCard.id,
    year: vehicleSummary?.vehicleCard.year,
    make: vehicleSummary?.vehicleCard.make,
    model: vehicleSummary?.vehicleCard.model,
    vin: vehicleSummary?.vehicleCard.vin,
    stockNumber: vehicleSummary?.vehicleCard.stockNumber,
  };
};

const getSharedTenantIdByStepId = (stepId: string) => {
  return interceptorSharedStepsMap?.[stepId]?.tenantId;
};

const sendInventoryEvent = (
  toTenantIds: string[],
  fromTenantId: string | undefined,
  message?: MessageContent,
  room?: string
) => {
  toTenantIds.forEach((toTenantId) => {
    const eventRoom = room ?? `tenant/${toTenantId}/inventory`;
    const event = `${eventRoom}/update`;
    interceptorSocket?.emit('reconVelocity', {
      ...message,
      room: eventRoom,
      event,
      tenantId: fromTenantId,
    });
  });
};

export let unRegisterFetchIntercept = () => {};

export const registerFetchIntercept = () => {
  unRegisterFetchIntercept = fetchIntercept.register({
    response: function (response: any) {
      if (!(interceptorSocket && interceptorTenantId)) {
        return response;
      }

      const method = response?.request?.method;
      const tenantId = interceptorTenantId;

      if (response.ok && mutationMethods.includes(method)) {
        const url = response.url;
        const inventoryId = getInventoryId(url);
        const eventType = getEventType(url, method);

        const sendToTenantIds = [tenantId];
        if (interceptorDealerVendorTenantIds) {
          sendToTenantIds.push(...interceptorDealerVendorTenantIds);
        }

        if (inventoryId) {
          const vehicleSummary =
            getVehicleSummaryFromQueryCacheByInventoryId(inventoryId);

          const inventoryTenantId = vehicleSummary?.vehicleCard.location.id;
          if (inventoryTenantId && inventoryTenantId !== interceptorTenantId) {
            sendToTenantIds.push(inventoryTenantId);
          }

          const sharedStepTenantId = getSharedTenantIdByStepId(
            vehicleSummary?.stepItem?.id ?? ''
          );

          if (sharedStepTenantId) {
            sendToTenantIds.push(sharedStepTenantId);
          }

          let room: string | undefined;
          if (getIsTagUpdate(url, inventoryId)) {
            room = `tenant/${interceptorTenantId}/tags`;
          }

          sendInventoryEvent(
            sendToTenantIds,
            tenantId,
            {
              eventType,
              ...getInventoryDetailsFromVehicleSummary(vehicleSummary),
            },
            room
          );

          if (eventType === EventType.stepChange) {
            response
              .clone()
              .json()
              .then((json: any) => {
                const responseSharedStepTenantId: string =
                  getSharedTenantIdByStepId(json?.data?.id) ?? '';

                if (responseSharedStepTenantId !== sharedStepTenantId) {
                  sendInventoryEvent([responseSharedStepTenantId], tenantId, {
                    eventType,
                    ...getInventoryDetailsFromVehicleSummary(vehicleSummary),
                  });
                }
              });
          }
        }

        if (eventType === EventType.create) {
          response
            .clone()
            .json()
            .then((json: APIResponse<VehicleSummary | undefined>) => {
              const responseVehicleSummary = json?.data;
              sendInventoryEvent(sendToTenantIds, tenantId, {
                eventType,
                ...getInventoryDetailsFromVehicleSummary(
                  responseVehicleSummary
                ),
              });
            });
        }
      }
      return response;
    },
  });
};

export const useSendInventoryUpdates = (
  socket: Socket | undefined,
  isLoading: boolean,
  tenantId: string | undefined,
  sharedStepsMap: any,
  dealerVendorTenantIds: string[] | undefined
) => {
  const isReady = socket && !isLoading && tenantId && sharedStepsMap;
  interceptorQueryClient = useQueryClient();

  useEffect(() => {
    if (isReady) {
      interceptorSocket = socket;
      interceptorTenantId = tenantId;
      interceptorSharedStepsMap = sharedStepsMap;
      interceptorDealerVendorTenantIds = dealerVendorTenantIds;
      return () => {
        interceptorSocket = undefined;
        interceptorTenantId = undefined;
        interceptorSharedStepsMap = undefined;
        interceptorDealerVendorTenantIds = undefined;
      };
    }
  }, [dealerVendorTenantIds, isReady, sharedStepsMap, socket, tenantId]);
};

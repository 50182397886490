import {
  Dialog,
  DialogActions,
  DialogContent,
  Button as MUIButton,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { RouteComponentProps } from '@reach/router';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import useGetInvoiceQuery from 'api/invoicing/useGetInvoiceQuery';
import useSendInvoiceMutation from 'api/invoicing/useSendInvoiceMutation';
import { strings } from 'common';
import ApiError from 'components/shared/ApiError';
import Button from 'components/shared/Button';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Invoice as InvoiceType } from 'models/invoicing';

import { Invoice } from '../Invoice';

import './ViewInvoice.scss';

type Props = RouteComponentProps & { invoiceId?: string };

const ViewInvoice = ({ navigate, invoiceId }: Props) => {
  const getInvoiceQuery = useGetInvoiceQuery(invoiceId);
  const sendInvoiceMutation = useSendInvoiceMutation();
  const [invoice, setInvoice] = useState<InvoiceType | undefined>(undefined);
  const apiError = getInvoiceQuery.error ?? sendInvoiceMutation.error;
  const [invoiceNumber, setInvoiceNumber] = useState(
    invoice?.invoiceNumber?.toString() ?? ''
  );
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (getInvoiceQuery.isSuccess) {
      setInvoice(getInvoiceQuery?.data?.data);
    }
  }, [getInvoiceQuery, getInvoiceQuery?.data?.data, getInvoiceQuery.isSuccess]);

  useEffect(() => {
    if (sendInvoiceMutation.isSuccess) {
      setInvoice(sendInvoiceMutation?.data?.data as InvoiceType);
    }
  }, [sendInvoiceMutation?.data?.data, sendInvoiceMutation.isSuccess]);

  const handleBack = useCallback(() => {
    navigate?.('../');
  }, [navigate]);

  const handleInvoiceNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInvoiceNumber(event.target.value);
    },
    []
  );

  const handleSendInvoice = useCallback(async () => {
    if (invoice?.id) {
      try {
        const updatedInvoiceResult = await sendInvoiceMutation.mutateAsync({
          invoiceId: invoice.id,
          invoiceNumber,
        });
        setInvoice(updatedInvoiceResult?.data as InvoiceType);
        setShowDialog(true);
      } catch (error) {}
    }
  }, [invoice?.id, invoiceNumber, sendInvoiceMutation]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  let content = null;
  if (getInvoiceQuery.isLoading) {
    content = <LoadingIndicator />;
  } else if (invoice && getInvoiceQuery.isSuccess) {
    content = (
      <Invoice
        invoice={invoice}
        onInvoiceNumberChange={handleInvoiceNumberChange}
      />
    );
  }

  return (
    <div className="vendor-view-invoice">
      <header className="vendor-view-invoice-header">
        <MUIButton
          variant="text"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Back
        </MUIButton>
      </header>
      <main className="vendor-view-invoice-content">{content}</main>
      {invoice && !invoice?.invoiceNumber && (
        <footer className="vendor-view-invoice-footer">
          <Button
            variant="primary"
            type="button"
            onClick={handleSendInvoice}
            disabled={
              !invoiceNumber ||
              sendInvoiceMutation.isLoading ||
              sendInvoiceMutation.isSuccess
            }
          >
            {strings.SEND_INVOICE}
          </Button>
        </footer>
      )}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '350px',
          }}
        >
          <CheckCircleOutlineIcon
            style={{ color: green[500], fontSize: 150 }}
          />
          <Typography variant="subtitle1">Invoice sent!</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" type="button" onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {apiError && <ApiError error={apiError} />}
    </div>
  );
};

export default ViewInvoice;

import { useContext, useEffect, useRef, useState } from 'react';

import { permissions } from 'common';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import PermissionsGate from 'components/shared/PermissionsGate';
import VehicleImageView from 'components/shared/VehicleImageView';

import VehicleDetailsTable from '../components/VehicleCardDetailsTable';
import VehicleCardNotes from '../components/VehicleCardNotes';
import VehicleCardProgressBar from '../components/VehicleCardProgressBar';
import VehicleCardReconInventoryTabs from '../components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import { VehicleCardContext } from '../VehicleCard';

import './VehicleCardDesktop.scss';

const VehicleCardDesktop = () => {
  const { vehicle } = useContext(VehicleCardContext);
  const vehicleId = vehicle?.vehicleCard.id;

  const [showNotesTab, setShowNotesTab] = useState(false);

  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  useEffect(() => {
    if (ref?.current?.offsetWidth < 799) {
      setShowNotesTab(true);
    } else {
      setShowNotesTab(false);
    }
  }, [ref?.current?.offsetWidth]);

  return (
    <div ref={ref} className="VehicleCardDesktop">
      <div className="VehicleCardDesktop-details">
        <div className="VehicleCardDesktop-vehicleInfo">
          <VehicleImageView
            thumbnail
            imageUrl={vehicle?.vehicleCard.vehicleImage}
            skeleton={!vehicle}
          />
          <VehicleCardYearMakeModel />
        </div>

        <VehicleDetailsTable showVin={false} />

        <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
          <TagList
            showEditButton
            vehicleIsSold={vehicle?.vehicleCard?.sold ?? true}
            initialTags={vehicle?.tags}
            vehicleId={vehicleId!}
          />
        </PermissionsGate>

        <VehicleCardProgressBar />
      </div>

      <div className="border-right" />
      <VehicleCardReconInventoryTabs
        showingInvoicing={false}
        showNotesTab={showNotesTab}
      />
      {!showNotesTab && (
        <>
          <div className="border-right" />
          <VehicleCardNotes
            notes={vehicle?.notesByDate!}
            vehicleId={vehicleId!}
          />
        </>
      )}
    </div>
  );
};

export default VehicleCardDesktop;

import { FC } from 'react';

import strings from 'common/strings';
import { DealerStepReportVehicleV2 } from 'models';

import { StepReportTableRow } from './StepReportTableRow';

interface StepReportTableProps {
  selectedVehicles: DealerStepReportVehicleV2[];
}

export const StepReportTable: FC<StepReportTableProps> = ({
  selectedVehicles,
}) => (
  <div className="VehicleStepReportModal-content">
    <div className="VehicleStepReportModal-titleRow">
      <div className="VehicleStepReportModal-vehicleInfoTitle">
        {strings.VEHICLE_INFO}
      </div>
      <div className="VehicleStepReportModal-reconditionEffortTitle">
        {strings.RECONDITION_EFFORT}
      </div>
      <div className="VehicleStepReportModal-reconditionCostsTitle">
        {strings.RECONDITION_COSTS}
      </div>
    </div>
    <div className="VehicleStepReportModal-table">
      <div className="VehicleStepReportModal-tableHead">
        <div className="VehicleStepReportModal-tableHead-vehicleInfo">
          <div>Stock #</div>
          <div>
            {strings.YEAR}/<wbr />
            {strings.MAKE}/<wbr />
            {strings.MODEL}
          </div>
        </div>
        <div className="VehicleStepReportModal-tableHead-reconditionEffort">
          <div>{strings.RETAIL_READY_TIME_IN_STEP}</div>
          <div>{strings.RECON_TIME_IN_STEP}</div>
          <div>{strings.RETAIL_READY_TIME}</div>
          <div>{strings.RECON_TIME}</div>
        </div>
        <div className="VehicleStepReportModal-tableHead-reconditionCost">
          <div>{strings.ESTIMATED}</div>
          <div>{strings.ACTUAL}</div>
          <div>{strings.OVERAGE}</div>
        </div>
      </div>
      <div className="VehicleStepReportModal-tableBody">
        {selectedVehicles.length > 0 ? (
          selectedVehicles.map((item, index) => (
            <StepReportTableRow
              item={item}
              key={`step-report-table-row-${index}`}
            />
          ))
        ) : (
          <div className="VehicleStepReportModal-tableDataRow">
            <div className="p-5">{strings.NO_COMPLETED_DATA}</div>
          </div>
        )}
      </div>
    </div>
  </div>
);

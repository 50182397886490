import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles'; // eslint-disable-line import/no-extraneous-dependencies
import { LocationProvider } from '@reach/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { hot } from 'react-hot-loader';

import { defaultQueryFn, queryCacheStrategies, reactQueryRetry } from 'api';
import { registerFetchIntercept } from 'hooks/useInventoryUpdates';

import theme from '../common/theme';
import AppRouter from '../navigation';

registerFetchIntercept();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      ...(queryCacheStrategies.basic as any),
      retry: reactQueryRetry,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

const App: React.FC = () => {
  return (
    <LocationProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </LocationProvider>
  );
};

export default hot(module)(App);

import { FC } from 'react';

import { strings } from 'common';

interface GridOverviewProps {
  //
}

const GridOverview: FC<GridOverviewProps> = () => (
  <div className="DealerStepReport-Overview">
    <div className="DealerStepReport-title">{strings.OVERVIEW}</div>
    <div></div>
  </div>
);

export default GridOverview;

import { FC, useState } from 'react';

import { useDealerStepReport, useDealerStepReportDownloadLink } from 'api';
import { DateRange } from 'common/DateRange';
import ReportContainer from 'components/pages/Reports/ReportContainer';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { TabProps } from 'components/shared/Tabs/Tabs';

import VehicleStepReportModalV3 from '../VehicleStepReportModalV3';
import GridOverview from './GridOverview';
import StepSummary from './StepSummary';
import { useVehicleStepReportModalState } from './useVehicleStepReportModalState';

import './DealerStepReportV3.scss';

interface DealerStepReportV3Props extends TabProps {
  dateRange: DateRange;
  corporateTenantId?: string;
  displayOverView?: boolean;
}

const DealerStepReportV3: FC<DealerStepReportV3Props> = ({
  dateRange,
  corporateTenantId,
  displayOverView = true,
}) => {
  const dealerStepReportQuery = useDealerStepReport(
    dateRange,
    corporateTenantId
  );
  const dealerStepReport = dealerStepReportQuery.data?.data;

  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);

  const { vehicleStepReportModalState, onOpenStepModal, onCloseStepModal } =
    useVehicleStepReportModalState();

  const { isLoading, downloadFileUrl = '' } = useDealerStepReportDownloadLink(
    selectedReportType,
    dateRange,
    corporateTenantId
  );

  if (!dealerStepReport) {
    return null;
  }

  return (
    <ReportContainer
      className="DealerStepReportV3"
      loading={dealerStepReportQuery.isLoading}
      error={dealerStepReportQuery.error}
    >
      {displayOverView && <GridOverview />}

      <StepSummary
        dealerStepReport={dealerStepReport}
        isDownloading={isLoading}
        downloadFileUrl={downloadFileUrl}
        setSelectedReportType={setSelectedReportType}
        openStepModal={onOpenStepModal}
      />

      {vehicleStepReportModalState.step && (
        <VehicleStepReportModalV3
          onClose={onCloseStepModal}
          showModal={vehicleStepReportModalState.open}
          step={vehicleStepReportModalState.step}
          dateRange={dateRange}
          tenantId={corporateTenantId}
        />
      )}
    </ReportContainer>
  );
};

export default DealerStepReportV3;

import { merge } from 'lodash';

import { getAPIHeaders, handleUpdateDeviceToken } from 'api';
import { strings } from 'common';

import { getNewTokenData } from './lib';

export async function configuredFetch(
  path: string,
  options: any = {},
  isRetrying: boolean = false
): Promise<Response> {
  const headers = await getAPIHeaders(options.method, options.headers);

  const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
    headers?.options,
    options
  );

  if (!headers?.options?.headers?.Authorization) {
    throw new Error('No Auth Token');
  }

  const response = await fetch(`${headers?.host}${path}`, requestOptions);
  if (response.status === 401) {
    if (!isRetrying) {
      const authBearerToken = await getNewTokenData();
      if (authBearerToken?.token) {
        handleUpdateDeviceToken();
        return configuredFetch(path, options, true);
      }
    }
  }

  if (!response.ok) {
    const apiMessage = await response?.text?.();
    if (apiMessage) {
      throw new Error(JSON.parse(apiMessage).message);
    }
    throw new Error(`Network response was not ok: ${response.status}`);
  }

  return response;
}

export async function configuredFetchText(path: string): Promise<string> {
  const response = await configuredFetch(path, { 'content-type': 'text/html' });
  return response.text();
}

export async function apiBlobFetcher(path: string) {
  const data = await configuredFetch(path, {
    'content-type': 'application/pdf',
  })
    .then((res) => res.blob())
    .catch((error) => {
      if (error?.message?.indexOf(404)) {
        throw new Error(strings.VEHICLE_NOT_FOUND_ALERT_MESSAGE);
      }
      throw new Error(strings.WINDOW_STICKER_SERVICE_NOT_AVAILABLE);
    });
  return URL.createObjectURL(data);
}

import { navigate, useLocation } from '@reach/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {
  APIResponse,
  INVENTORY_PAGE_SIZE,
  useCreateEngagement,
  useToken,
  useVehicleList,
} from 'api';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Alert from 'components/shared/Alert';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { BOOTSTRAP_BREAKPOINT } from 'context';
import { useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';
import { vehicleDetailsBuilder } from 'navigation/routes';
import {
  getLastQueryParamWithName,
  updateUrlSearchParam,
} from 'navigation/util/ParamHelpers';

import VehicleCard from '../VehicleCard';
import { useVehicleCardInfo } from '../VehicleCard/VehicleCard';

import './VehicleList.scss';

interface VehicleListItemProps {
  index: number;
  isShowroomMode: boolean;
  isLoading: boolean;
  vehicleListData: {
    pages: APIResponse<VehicleSummary[]>[];
    pageParams: number[];
  };
  vehicleCardHeight: number;
  setIsVehicleCardErrorDisplayed: (
    isVehicleCardErrorDisplayed: boolean
  ) => void;
  setEngageSendToShopperVehicles: (vehicles: VehicleSummary[]) => void;
  setIsSendVehicleModalDisplayed: (isDisplayed: boolean) => void;
}

const VehicleListItem = ({
  index,
  isShowroomMode,
  isLoading,
  vehicleListData,
  vehicleCardHeight,
  setIsVehicleCardErrorDisplayed,
  setEngageSendToShopperVehicles,
  setIsSendVehicleModalDisplayed,
}: VehicleListItemProps) => {
  const location = useLocation();
  const windowSize = useWindowSize();
  const { data: tokenData, isLoading: isLoadingToken } = useToken();
  const [vehicle, setVehicle] = useState<VehicleSummary>();
  const [userId, setUserId] = useState<string>();
  const createEngagementAsync = useCreateEngagement();
  const pageIndexContainingVehicle = Math.floor(index / INVENTORY_PAGE_SIZE);

  const isPreviewed = useMemo(() => {
    if (!vehicle || !location.search) return false;
    return (
      `${vehicle.vehicleCard.id},${vehicle.vehicleCard.stockNumber}` ===
      getLastQueryParamWithName('preview')
    );
  }, [vehicle, location.search]);

  useEffect(() => {
    const page = vehicleListData?.pages?.[pageIndexContainingVehicle]?.data;
    if (!isLoading && page) {
      const vehicleIndexOnPage = index % INVENTORY_PAGE_SIZE;
      setVehicle(page[vehicleIndexOnPage]);
    }
  }, [vehicleListData, index, isLoading, pageIndexContainingVehicle]);

  useEffect(() => {
    if (!isLoadingToken && tokenData?.user?.id) {
      setUserId(tokenData.user.id);
    }
  }, [tokenData, isLoadingToken]);

  const handleSelectVehicle = useCallback(async () => {
    if (!vehicle) return;
    if (windowSize.width < 1024) return;
    const previewPanelIsVisible = windowSize.width >= BOOTSTRAP_BREAKPOINT.lg;
    if (previewPanelIsVisible) {
      if (!isPreviewed) {
        const preview = `${vehicle.vehicleCard.id},${vehicle.vehicleCard.stockNumber}`;
        updateUrlSearchParam({ preview });
      }
    } else if (vehicle?.vehicleCard?.id) {
      const vdpURL = `${vehicleDetailsBuilder(
        vehicle.vehicleCard.id!,
        false
      )}?fromVDP=inventory`;
      await navigate(vdpURL);
    }
  }, [isPreviewed, vehicle, windowSize.width]);

  if (isLoading || !vehicle) {
    return null;
  }

  const isOnboarding =
    !!vehicleListData?.pages?.[0]?.meta?.show_onboarding_banner;

  return (
    <VehicleCard
      key={vehicle?.vehicleCard?.id}
      contextData={{
        vehicle: vehicle,
        forceShowEngageTab: true,
        inExtensionCardMode: false,
        isOnboarding: isOnboarding,
        isShowroomMode,
        position: index,
      }}
      height={vehicleCardHeight}
      config={{
        onVehicleCardEngageSendClick: () => {
          if (!vehicle) return;
          setEngageSendToShopperVehicles([vehicle]);
          setIsSendVehicleModalDisplayed(true);
        },
        onViewCVDPClick: async () => {
          if (vehicle && vehicle.vehicleCard.id && userId) {
            if (!userId) return;
            let response;
            try {
              response = await createEngagementAsync.mutateAsync({
                vehicleId: vehicle.vehicleCard.id,
                prospectIdList: [],
                senderId: userId,
                showPrice: true,
                engagementType: 'SHOWROOM',
              });
            } catch (e) {
              setIsVehicleCardErrorDisplayed(true);
              return;
            }

            if (
              Array.isArray(response?.data) &&
              response?.data?.[0]?.shareURL
            ) {
              const shareURL = response?.data?.[0]?.shareURL;
              window.open(shareURL, '_newtab');
            } else {
              setIsVehicleCardErrorDisplayed(true);
            }
          }
        },
      }}
      onPreviewVehicle={handleSelectVehicle}
      isPreviewed={isPreviewed}
    />
  );
};

const ItemRenderer: React.FunctionComponent<ListChildComponentProps> = ({
  index,
  style,
  data,
}) => {
  const {
    columnIndexes,
    columnCount,
    vehicleListData,
    isLoading,
    rowCount,
    setIsVehicleCardErrorDisplayed,
    setEngageSendToShopperVehicles,
    setIsSendVehicleModalDisplayed,
    vehicleCardHeight,
    isShowroomMode,
  } = data;

  return (
    <div style={style as React.CSSProperties}>
      <Row className="row-margins">
        {columnIndexes.map((columnIndex: number) => (
          <Col
            key={columnIndex}
            className={`${rowCount > 1 ? 'VehicleList2-padding' : ''}`}
          >
            <VehicleListItem
              key={columnIndex}
              isShowroomMode={isShowroomMode}
              index={index * columnCount + columnIndex}
              isLoading={isLoading}
              vehicleListData={vehicleListData}
              vehicleCardHeight={vehicleCardHeight}
              setIsVehicleCardErrorDisplayed={setIsVehicleCardErrorDisplayed}
              setEngageSendToShopperVehicles={setEngageSendToShopperVehicles}
              setIsSendVehicleModalDisplayed={setIsSendVehicleModalDisplayed}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

interface VehicleListProps {
  setEngageSendToShopperVehicles: (vehicles: VehicleSummary[]) => void;
  setIsSendVehicleModalDisplayed: (isDisplayed: boolean) => void;
  isShowroomMode: boolean;
}

const VehicleList = ({
  setEngageSendToShopperVehicles,
  setIsSendVehicleModalDisplayed,
  isShowroomMode,
}: VehicleListProps) => {
  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useVehicleList();

  const windowSize = useWindowSize();
  const [totalVehicleCount, setTotalVehicleCount] = useState(0);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [isVehicleCardErrorDisplayed, setIsVehicleCardErrorDisplayed] =
    useState(false);

  const { cardHeight } = useVehicleCardInfo(isShowroomMode);
  const extraPadding = cardHeight === 136 ? 18 : 41;
  const listItemHeight = cardHeight + extraPadding;

  const columnCount = windowSize.width < 960 && windowSize.width > 846 ? 2 : 1;
  const rowCount = Math.ceil(vehicleCount / columnCount);
  const columnIndexes: number[] = [];

  for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
    columnIndexes.push(columnIndex);
  }

  useEffect(() => {
    if (!isLoading) {
      setTotalVehicleCount(data?.pages?.[0]?.meta?.totalRecords ?? 0);
      setVehicleCount(
        data?.pages?.reduce(
          (acc: Array<any>, vehicles) => [...acc, ...vehicles.data],
          []
        ).length ?? 0
      );
    }
  }, [data?.pages, isLoading, vehicleCount]);

  const isItemLoaded = useCallback(
    (index: number) => {
      const hasNextPage = vehicleCount < totalVehicleCount;
      const checkIndex = columnCount === 1 ? index : index * columnCount;
      return !hasNextPage || checkIndex < vehicleCount;
    },
    [columnCount, totalVehicleCount, vehicleCount]
  );

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      if (!isFetchingNextPage) {
        fetchNextPage({
          pageParam: {
            startIndex: (data?.pages?.length ?? 0) * INVENTORY_PAGE_SIZE,
          },
        }).then(() => Promise.resolve());
      }

      return Promise.resolve();
    },
    [isFetchingNextPage, fetchNextPage, data?.pages?.length]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div
      data-vas-testing={testIds.INVENTORY_VEHICLE_LIST}
      className="VehicleList2 full-height full-width flex-rows"
    >
      <Alert
        open={isVehicleCardErrorDisplayed}
        duration={2000}
        contentProps={{
          message: strings.VIEW_CVDP_ERROR_MESSAGE,
          variant: 'error',
          onClose: () => setIsVehicleCardErrorDisplayed(false),
        }}
      />
      <div className="VehicleList-list-container flex-grow full-width">
        <AutoSizer>
          {({ height }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={totalVehicleCount}
              threshold={Math.floor(INVENTORY_PAGE_SIZE / 2)}
              minimumBatchSize={INVENTORY_PAGE_SIZE}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height}
                  width="100%"
                  style={{ overflowX: 'hidden' }}
                  itemCount={rowCount}
                  itemSize={listItemHeight}
                  itemData={{
                    columnIndexes,
                    columnCount,
                    vehicleListData: data,
                    isLoading,
                    isShowroomMode,
                    vehicleCount,
                    setIsVehicleCardErrorDisplayed,
                    setEngageSendToShopperVehicles,
                    setIsSendVehicleModalDisplayed,
                    cardHeight,
                  }}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {ItemRenderer}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
        {rowCount === 0 && (
          <span className="vendor-no-vehicles full-height full-width flex-rows">
            {strings.NO_INVENTORY_FOUND}
          </span>
        )}
      </div>
    </div>
  );
};

export default VehicleList;

import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { defaultMutationFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

type UpdateInvoiceVariables = {
  invoiceId: string;
};

const usePayInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UpdateInvoiceVariables) => {
      return defaultMutationFn(
        `/vendor-invoice/${data.invoiceId}/statusPaid`,
        'PUT'
      ) as Promise<UseMutationResult<Invoice>>;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['/inventory/invoicedInventories']);
      },
    }
  );
};

export default usePayInvoiceMutation;

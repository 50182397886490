import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getInventoryQueryParams } from 'api/invoicing';
import { strings } from 'common';
import VehicleSearch from 'components/pages/VehicleCollectionBrowser/VehicleSearch/VehicleSearch';
import { InvoiceStatus } from 'models/invoicing';
import { updateUrlSearchParam } from 'navigation/util/ParamHelpers';

import './Header.scss';

const Header = () => {
  const [open, setOpen] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState(
    getInventoryQueryParams()?.s
  );

  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus | ''>(
    getInventoryQueryParams()?.invoiceStatus
  );

  const anchorRef = useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus?.();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event?: { target: any }) => {
    if (anchorRef.current?.contains?.(event?.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: { key: string; preventDefault: () => void }) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    []
  );

  const handleListItemSelect = useCallback(
    (value: InvoiceStatus) => {
      if (value === 'All') {
        updateUrlSearchParam({ invoiceStatus: '' });
        setInvoiceStatus('');
      } else {
        updateUrlSearchParam({ invoiceStatus: value });
        setInvoiceStatus(value);
      }
      handleClose();
    },
    [handleClose, setInvoiceStatus]
  );

  return (
    <div className="vendor-header">
      <VehicleSearch
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        placeholder={strings.SEARCH_INVOICING}
      />
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="small"
        >
          <FilterListIcon color="secondary" />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    dense
                  >
                    <MenuItem
                      dense
                      selected={invoiceStatus === 'All'}
                      onClick={() => handleListItemSelect('All')}
                    >
                      {strings.ALL}
                    </MenuItem>
                    <MenuItem
                      dense
                      selected={invoiceStatus === 'PENDING'}
                      onClick={() => handleListItemSelect('PENDING')}
                    >
                      {strings.PENDING_INVOICES}
                    </MenuItem>
                    <MenuItem
                      dense
                      selected={invoiceStatus === 'PAID'}
                      onClick={() => handleListItemSelect('PAID')}
                    >
                      {strings.PAID_INVOICES}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Header;

import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { addNoteToQueryCache, fetchNote, useAddNote } from 'api';
import { strings } from 'common';
import { getUrl } from 'store/uploads/helpers';
import { MAX_UPLOADS, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';

const handleSelectedFiles = async (
  vehicleId: string,
  files: any,
  uploadStoreData: any,
  addNoteAsync: any,
  message?: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const uppyFiles = uploadStoreData.tusUppy
        ?.getFiles()
        .concat(uploadStoreData.ajaxUppy?.getFiles() || []);

      if (uppyFiles && uppyFiles.length + files.length > MAX_UPLOADS) {
        reject(strings.TOO_MANY_FILES);
        return;
      }

      const savedNote = await addNoteAsync({
        vehicleId: vehicleId!,
        note: message,
        skipAddToCache: true,
      });

      const onUploadSuccess = async () => {
        resolve({ noteId: savedNote?.data?.id });
      };

      const onUploadError = (message?: string) => {
        if (message) {
          reject(message);
        }
        reject('uploadError');
      };

      const onUploadCancel = () => {};

      const uploadTarget = { type: UploadType.NOTE };

      await uploadStoreData.addFiles(
        files,
        vehicleId!,
        uploadTarget,
        onUploadSuccess,
        onUploadError,
        onUploadCancel
      );

      if (savedNote?.data?.id && files) {
        uploadStoreData.addNoteTargetId(vehicleId!, savedNote.data.id);
        const { baseUrl } = await uploadStoreData.getUploadUrlData();
        const { xhrUploadState } = uploadStoreData.ajaxUppy?.getState() || {
          xhrUploadState: undefined,
        };
        uploadStoreData.ajaxUppy?.setState({
          xhrUpload: {
            ...xhrUploadState,
            endpoint: getUrl(
              baseUrl!,
              '',
              vehicleId!,
              UploadType.NOTE,
              savedNote.data.id,
              undefined,
              false
            ),
          },
        });
        uploadStoreData.upload();
      } else {
        resolve({});
      }
    } catch (error) {
      console.error({ error });
      reject('Error Uploading Files');
    }
  });
};

const handleAddNotesToQuery = async (
  vehicleId: string,
  noteId: string,
  queryClient: any
) => {
  if (noteId) {
    const note = await fetchNote(vehicleId, noteId)
      .then((res) => res.json())
      .then(({ data }) => data);
    addNoteToQueryCache(vehicleId, queryClient, note);
  }
};

// TODO: use this implentation for the notes in VDP.
export const useAttachments = () => {
  const queryClient = useQueryClient();
  const uploadStoreData = useUploadStore();
  const { addNoteAsync } = useAddNote();

  const [isLoading, setIsLoading] = useState(false);

  const createNoteAndUploadAttachments = async (
    vehicleId: string,
    attachments: any,
    message?: string
  ) => {
    setIsLoading(true);
    return await handleSelectedFiles(
      vehicleId,
      attachments,
      uploadStoreData,
      addNoteAsync,
      message
    )
      .then(({ noteId }: any) =>
        handleAddNotesToQuery(vehicleId, noteId, queryClient)
      )
      .finally(() => setIsLoading(false));
  };

  return { createNoteAndUploadAttachments, isLoading };
};

import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  APIResponse,
  configuredFetch,
  defaultMutationFn,
  defaultTextPlainMutationFn,
  getVehicleListParams,
} from 'api';
import { getDealerInventoryQueryKey } from 'api/invoicing/useGetDealerInventoryQuery';
import { getVendorInventoryQueryKey } from 'api/invoicing/useGetVendorInventoryQuery';
import {
  Attachment,
  Note,
  NotesByDate,
  NotesByUser,
  NoteV2,
  VehicleSummary,
} from 'models';
import { getCalendarDateStringFromDate } from 'utils/time';

export async function fetchNote(inventoryId: string, noteId: string) {
  const path = `/inventory/${inventoryId}/notes/${noteId}`;
  return configuredFetch(path);
}

export function useDeleteNote() {
  const queryClient = useQueryClient();

  type DeleteNoteData = { vehicleId: string; noteId: string };
  const mutation = useMutation(({ vehicleId, noteId }: DeleteNoteData) => {
    const path = `/inventory/${vehicleId}/notes/${noteId}`;
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteNoteAsync({ vehicleId, noteId }: DeleteNoteData) {
    return mutation.mutateAsync(
      { vehicleId, noteId },
      {
        onSuccess: () => {
          deleteNoteFromQueryCache(vehicleId, queryClient, noteId);
        },
      }
    );
  }

  return {
    ...mutation,
    deleteNoteAsync,
  };
}

export function useDeleteNoteAttachment() {
  const queryClient = useQueryClient();

  type NoteAttachmentData = {
    vehicleId: string;
    noteId: string;
    attachmentId: string;
  };
  const mutation = useMutation(
    ({ vehicleId, noteId, attachmentId }: NoteAttachmentData) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}/attachments/${attachmentId}`;
      return defaultMutationFn(path, 'DELETE');
    }
  );

  async function deleteNoteAttachmentAsync({
    vehicleId,
    noteId,
    attachmentId,
  }: NoteAttachmentData) {
    return await mutation.mutateAsync(
      {
        vehicleId,
        noteId,
        attachmentId,
      },
      {
        onSuccess: () =>
          deleteNoteFromQueryCache(
            vehicleId,
            queryClient,
            noteId,
            attachmentId
          ),
      }
    );
  }

  return {
    ...mutation,
    deleteNoteAttachmentAsync,
  };
}

export function useUpdateNote(vehicleId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ noteId, note }: { noteId: string; note: string }) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}`;
      return defaultTextPlainMutationFn(path, 'PUT', note);
    },
    {
      onSuccess: (data) => {
        updateNotesQueryCache(vehicleId, queryClient, data.data);
      },
    }
  );
}

type AddNoteData = {
  vehicleId: string;
  note: string;
  skipAddToCache?: boolean;
};
export function useAddNote() {
  const queryClient = useQueryClient();
  const mutation = useMutation(({ vehicleId, note }: AddNoteData) => {
    const path = `/inventory/${vehicleId}/notes`;
    return defaultTextPlainMutationFn(path, 'POST', note);
  });

  async function addNoteAsync({
    vehicleId,
    note,
    skipAddToCache,
  }: AddNoteData): Promise<{ data: { id: string } }> {
    return mutation.mutateAsync(
      { vehicleId, note },
      {
        onSuccess: (data) => {
          if (skipAddToCache) {
            return;
          }
          addNoteToQueryCache(vehicleId, queryClient, data.data);
        },
      }
    );
  }

  return {
    ...mutation,
    addNoteAsync,
  };
}

function addToInventoryNotes(inventoryNotes: NotesByDate[], note: Note) {
  const { sender, note: NoteV2, timestamp, attachments, id } = note ?? {};
  const date = getCalendarDateStringFromDate(new Date(timestamp));
  const fullName = `${sender?.firstName} ${sender?.lastName}`.trim();
  const newNoteV2 = { note: NoteV2, time: timestamp, attachments, id };
  const newNoteByUser = { fullName, notes: [newNoteV2], userId: sender?.id };
  const newNoteByDate = { date, timestamp, notesByUser: [newNoteByUser] };

  if (!inventoryNotes?.length) {
    inventoryNotes = [newNoteByDate];
  } else {
    let foundDate = false;
    inventoryNotes = inventoryNotes.map((notesByDate) => {
      const { notesByUser = [] } = notesByDate ?? {};

      if (notesByDate.date === date) {
        foundDate = true;
        const lastNote = notesByUser[notesByUser.length - 1];

        if (lastNote.fullName === fullName) {
          lastNote.notes = lastNote.notes?.concat(newNoteV2);
        } else {
          notesByDate.notesByUser = notesByUser.concat(newNoteByUser);
        }
      }
      return notesByDate;
    });

    if (!foundDate) {
      inventoryNotes = inventoryNotes.concat(newNoteByDate);
    }
  }
  return inventoryNotes;
}

export function addNoteToQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  note: Note
) {
  if (note?.attachments || note?.note) {
    // TODO: update to @tanstack/react-query
    // https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientsetquerydata
    // returning undefined will not create cache entry
    const vehicleNotesQuery = `/inventory/${vehicleId}/notes`;
    if (queryClient.getQueryData([vehicleNotesQuery])) {
      queryClient.setQueryData([vehicleNotesQuery], (data: any) => {
        if (data?.data) {
          data.data = data.data?.concat(note) ?? [note];
        }
        return data;
      });
    }

    const vehicleQuery = `/inventory/${vehicleId}`;
    if (queryClient.getQueryData([vehicleQuery])) {
      queryClient.setQueryData([vehicleQuery], (data: any) => {
        if (data?.data) {
          data.data.notes = addToInventoryNotes(data.data?.notes, note);
        }
        return data;
      });
    }

    const { queryKey: vehicleListQueryKey } = getVehicleListParams();
    queryClient.setQueryData(vehicleListQueryKey, (data: any) => {
      data?.pages?.map((page: APIResponse<VehicleSummary[]>) => {
        page.data?.map((summary) => {
          if (summary.vehicleCard?.id === vehicleId) {
            summary.notesByDate = addToInventoryNotes(
              summary?.notesByDate,
              note
            );
          }
          return summary;
        });
        return page;
      });
      return data;
    });

    queryClient.setQueryData(getVendorInventoryQueryKey(), (data: any) => {
      data?.data?.map((summary: VehicleSummary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notesByDate = addToInventoryNotes(summary?.notesByDate, note);
        }
        return summary;
      });
      return data;
    });

    queryClient.setQueryData(getDealerInventoryQueryKey(), (data: any) => {
      data?.data?.map((summary: VehicleSummary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notesByDate = addToInventoryNotes(summary?.notesByDate, note);
        }
        return summary;
      });
      return data;
    });
  }
}

function deleteFromInventoryNotes(
  inventoryNotes: NotesByDate[],
  noteId: string,
  attachmentId?: string
) {
  inventoryNotes = inventoryNotes?.reduce<NotesByDate[]>(
    (prevNotes, currentNote) => {
      currentNote.notesByUser = currentNote.notesByUser.reduce<NotesByUser[]>(
        (prevInnerNotes, currentInnerNote) => {
          currentInnerNote.notes = currentInnerNote.notes.reduce<NoteV2[]>(
            (prevMessages, currentMessage) => {
              if (currentMessage.id === noteId) {
                if (attachmentId) {
                  currentMessage.attachments =
                    currentMessage.attachments.reduce<Attachment[]>(
                      (prevAttachments, currAttachment) => {
                        if (currAttachment.id === attachmentId) {
                          return prevAttachments;
                        }
                        return prevAttachments.concat(currAttachment);
                      },
                      []
                    );
                } else {
                  return prevMessages;
                }
              }
              return prevMessages.concat(currentMessage);
            },
            []
          );
          if (!currentInnerNote?.notes?.length) {
            return prevInnerNotes;
          }
          return prevInnerNotes.concat(currentInnerNote);
        },
        []
      );
      if (!currentNote?.notesByUser?.length) {
        return prevNotes;
      }
      return prevNotes.concat(currentNote);
    },
    []
  );
  return inventoryNotes;
}

function deleteNoteFromQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  noteId: string,
  attachmentId?: string
) {
  if (attachmentId) {
    queryClient.invalidateQueries([`/inventory/${vehicleId}/notes`]);
  } else {
    const vehicleNotesQuery = `/inventory/${vehicleId}/notes`;
    if (queryClient.getQueryData([vehicleNotesQuery])) {
      queryClient.setQueryData([vehicleNotesQuery], (data: any) => {
        if (data) {
          const { data: notes } = data;
          const index = notes.findIndex((n: { id: string }) => n.id === noteId);

          return {
            ...data,
            data: [...notes.slice(0, index), ...notes.slice(index + 1)],
          };
        }
        return data;
      });
    }
  }

  const vehicleQuery = `/inventory/${vehicleId}`;
  if (queryClient.getQueryData([vehicleQuery])) {
    queryClient.setQueryData([vehicleQuery], (data: any) => {
      if (data?.data) {
        data.data.notes = deleteFromInventoryNotes(
          data.data?.notes,
          noteId,
          attachmentId
        );
      }
      return data;
    });
  }

  const { queryKey: vehicleListQueryKey } = getVehicleListParams();
  queryClient.setQueryData(vehicleListQueryKey, (data: any) => {
    data?.pages?.map((page: APIResponse<VehicleSummary[]>) => {
      page.data?.map((summary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notesByDate = deleteFromInventoryNotes(
            summary?.notesByDate,
            noteId,
            attachmentId
          );
        }
        return summary;
      });
      return page;
    });
    return data;
  });

  queryClient.setQueryData(getVendorInventoryQueryKey(), (data: any) => {
    data?.data?.map((summary: VehicleSummary) => {
      if (summary.vehicleCard?.id === vehicleId) {
        summary.notesByDate = deleteFromInventoryNotes(
          summary?.notesByDate,
          noteId,
          attachmentId
        );
      }
      return summary;
    });
    return data;
  });

  queryClient.setQueryData(getDealerInventoryQueryKey(), (data: any) => {
    data?.data?.map((summary: VehicleSummary) => {
      if (summary.vehicleCard?.id === vehicleId) {
        summary.notesByDate = deleteFromInventoryNotes(
          summary?.notesByDate,
          noteId,
          attachmentId
        );
      }
      return summary;
    });
    return data;
  });
}

function updateNotesQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  note: Note
) {
  const queryKey = `/inventory/${vehicleId}/notes`;
  const cachedNotes: APIResponse<Note[]> | undefined = queryClient.getQueryData(
    [queryKey]
  );

  cachedNotes?.data?.some?.((cachedNote, index) => {
    if (cachedNote.id === note.id) {
      const updatedNotes = Object.assign([], cachedNotes.data, {
        [index]: note,
      });
      queryClient.setQueriesData([queryKey], {
        ...cachedNotes,
        data: [...updatedNotes],
      });
      return true;
    }
    return false;
  });
}

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useLocation, useNavigate } from '@reach/router';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useCreateInvoiceMutation } from 'api/invoicing';
import { strings } from 'common';
import { STATUS } from 'components/pages/FlatTasks/FlatTask/FlatTask';
import Alert from 'components/shared/Alert';
import { useVendor } from 'hooks';
import { VehicleSummary } from 'models';

import VehicleCardTabFooter from '../components/VehicleCardReconInventoryTabs/VehicleCardTabFooter';

type Props = {
  vehicle: VehicleSummary;
};

const InvoiceTasks = ({ vehicle }: Props) => {
  const createInvoiceMutation = useCreateInvoiceMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isVendor } = useVendor();
  const [apiError, setApiError] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // TODO: Refine logic after NADA.  If any task has an invoice id
  // then a "View Invoice" button will show, otherwise a "Create Invoice" button will show
  const invoicedTask = useMemo(() => {
    return vehicle?.tasks?.find((task) => !!task.invoiceId);
  }, [vehicle?.tasks]);

  const handleCreateInvoice = useCallback(async () => {
    if (vehicle && vehicle?.tasks) {
      try {
        const { data: invoice } = await createInvoiceMutation.mutateAsync({
          inventoryId: vehicle.vehicleCard?.id!,
          taskIds: vehicle.tasks
            ?.filter((task) => task.status === 'COMPLETE')
            .map((task) => task.id!),
        });
        if (invoice) {
          navigate?.(`${location.pathname}/${invoice.id}`);
        }
      } catch (error: any) {
        console.log('Error creating invoice', error);
        setApiError(error.toString());
        setShowErrorAlert(true);
      }
    }
  }, [createInvoiceMutation, location, navigate, vehicle]);

  const handleViewInvoice = useCallback(async () => {
    const payPath = !isVendor && invoicedTask?.status !== 'PAID' ? '/pay' : '';
    navigate?.(`${location.pathname}/${invoicedTask?.invoiceId}${payPath}`);
  }, [
    invoicedTask?.invoiceId,
    invoicedTask?.status,
    isVendor,
    location.pathname,
    navigate,
  ]);

  const handleClickNavToRecon = useCallback(
    (clickSource: string): Promise<void> | null => {
      return null;
    },
    []
  );

  const handleErrorClose = useCallback(() => {
    setShowErrorAlert(false);
    setApiError('');
  }, []);

  const getCompletedTasksForVehicle = vehicle.tasks
    ?.filter((task) => task.status === 'COMPLETE')
    .map((task) => task.id!);

  if (!getCompletedTasksForVehicle) {
    return null;
  }

  return (
    <div className="VehicleCard-invoiceTasks">
      <div className="VehicleCard-invoiceTasks-container">
        <TableContainer elevation={0} component={Paper}>
          <Table
            size="small"
            className="Vehicle-invoiceTasks-table"
            aria-label="vendor tasks table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <span className="Vehicle-invoiceTasks-tableBold">Tasks</span>
                </TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicle?.tasks?.map((task) => (
                <TableRow key={task.id}>
                  <TableCell component="th" scope="row">
                    {task.label}
                  </TableCell>
                  <TableCell
                    className={`FlatTask-status-${(
                      STATUS[task.status] as string
                    ).toLowerCase()}`}
                    align="right"
                  >
                    {STATUS[task.status]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="Vehicle-invoiceTasks-buttonContainer">
        {invoicedTask ? (
          <Button
            className="Vehicle-invoiceTasks-viewButton"
            onClick={handleViewInvoice}
            disabled={
              createInvoiceMutation.isLoading || createInvoiceMutation.isSuccess
            }
          >
            {strings.VIEW_INVOICE}
          </Button>
        ) : (
          <Button
            className="Vehicle-invoiceTasks-createButton"
            onClick={handleCreateInvoice}
            disabled={
              createInvoiceMutation.isLoading ||
              createInvoiceMutation.isSuccess ||
              getCompletedTasksForVehicle.length < 1
            }
          >
            {strings.CREATE_INVOICE}
          </Button>
        )}
        <div className="Vehicle-invoiceTasks-badgeContainer">
          <VehicleCardTabFooter
            vehicle={vehicle}
            navClick={handleClickNavToRecon}
            showRecon={false}
            showRecalls={false}
          />
        </div>
      </div>
      <Alert
        open={showErrorAlert}
        contentProps={{
          message: apiError,
          variant: 'error',
          onClose: handleErrorClose,
        }}
      />
    </div>
  );
};

export default InvoiceTasks;

/* eslint-disable max-len */

const pj = require('../../package.json');

interface IStrings {
  [key: string]: any;
}

export const strings: IStrings = {
  ADD: 'Add',
  EDIT: 'Edit',

  LOGGED_IN_USER: 'LOGGED_IN_USER',
  LOGIN_SOURCE: 'Velocity Recon',

  /* Shared */
  EMAIL_FIELD: 'Email Address',
  INVALID_EMAIL_MESSAGE: '^Invalid email address',
  PASSWORD_FIELD: 'Password',
  LOADING: 'Loading...',
  DESCRIPTION: 'Description',
  DOWNLOAD: 'Download',
  PRINT_PDF: 'PRINT PDF',
  INVENTORY_ONLY: 'Inventory Only',
  DOWNLOAD_PDF: 'Download PDF',
  DOWNLOAD_XLS: 'Download XLS',

  /* Login */
  LOGIN: 'Login',
  LOGIN_APP_DESCRIPTION:
    'Welcome to Velocity Automotive, the most advanced vehicle platform for your dealership.',
  LOGIN_FORM_TITLE: 'Please login to continue.',
  LOGIN_KEEP_ME_LOGGED_IN: 'Keep me logged in',
  LOGIN_APP_VERSION: `App version ${pj.version}`,
  LOGIN_BUTTON_TEXT: 'LOG IN',
  LOGIN_EMAIL_FIELD: 'Email Address',
  LOGIN_PASSWORD_FIELD: 'Password',
  USER_CACHED_ERROR_MESSAGE: 'Error trying to get user data',

  /* Forgot Password */
  FORGOT_PASSWORD_TITLE: 'Reset Password',
  FORGOT_PASSWORD_DESCRIPTION:
    'Enter your email address and we will send instructions to reset your password',
  FORGOT_PASSWORD_EMAIL_SUCCESS:
    'Email has been sent. Please click on the link in your email to reset your password',
  FORGOT_PASSWORD_BUTTON_TEXT: 'RESET PASSWORD',
  FORGOT_PASSWORD_BUTTON_RETURN_TEXT: 'Return to Login',
  FORGOT_PASSWORD_CONFIRMATION_EMAIL: (email: string): string =>
    `An email has been sent to ${email}. Follow the instructions provided to reset your password.`,

  /* Change Password */
  CHANGE_PASSWORD_TITLE: 'Change Password',
  CHANGE_PASSWORD_DESCRIPTION: 'Enter your new password below and confirm it.',
  CHANGE_PASSWORD_SUBTITLE: 'Password Guidelines',
  CHANGE_PASSWORD_TEXT_1: '8 - 20 Characters',
  CHANGE_PASSWORD_TEXT_2: 'Includes uppercase and lowercase letters',
  CHANGE_PASSWORD_TEXT_3: 'Includes at least one number',
  CHANGE_PASSWORD_TEXT_4: 'Include one special character [@#$%^&+=!]',
  CONFIRM_PASSWORD_FIELD: 'Confirm Password',
  CURRENT_PASSWORD_FIELD: 'Current Password',
  NEW_PASSWORD_FIELD: 'New Password',
  CHANGE_PASSWORD_BUTTON_TEXT: 'Change Password',
  CHANGE_PASSWORD_VALIDATION_MESSAGE: '^Password must meet guidelines above',
  PASSWORDS_DONT_MATCH: '^Passwords do not match',
  PROVIDE_NEW_PASSWORD: '^Must provide new password',
  PROVIDE_CURRENT_PASSWORD: '^Must provide current password',
  CONFIRM_NEW_PASSWORD: '^Must confirm new password',

  /* Dashboard */
  GREETING_MORNING: 'Good Morning,',
  GREETING_AFTERNOON: 'Good Afternoon,',
  GREETING_EVENING: 'Good Evening,',
  NEEDS_ATTENTION: 'Needs Attention',
  NO_ASSIGNMENTS: 'No Assignments.',
  NO_ASSIGNMENTS_MESSAGE:
    'When a vehicle is assigned to you, it will appear here.',
  NO_INVENTORY_FOUND: 'No inventory found!',
  NO_VEHICLES_NEED_ATTENTION: 'No vehicles need attention.',
  NO_VEHICLES_NEED_ATTENTION_MESSAGE:
    'When a vehicle needs attention, it will appear here.',
  VEHICLE_NEEDS_ATTENTION_STEP_TIME: 'Step Time',
  VEHICLE_NEEDS_ATTENTION_LATE: 'Late',
  VEHICLE_NEEDS_ATTENTION_RECON_SPEND: 'Recon Spend',
  VEHICLE_NEEDS_ATTENTION_OVER_BUDGET: 'Over Budget',
  VEHICLE_NEEDS_ATTENTION_IDLE: 'Idle',
  VEHICLES_IN_RECON: 'Vehicles in Recon',
  HIGHLIGHTS_VARIANCE: 'Variance',
  ACTIVE_RECON_SUMMARY: 'Active Recon Summary',
  ACTIVE_RECON_VEHICLES: 'Vehicles',
  ACTIVE_RECON_GOAL_TIME: 'Goal Time',
  ACTIVE_RECON_AVG_STEP_TIME: 'Avg. Step Time',
  ACTIVE_RECON_OVERAGE: 'Overage',
  RECON_ISSUE: 'Recon Issue',
  EXCEEDED_GOAL_TIME: 'Late',
  BATTERY_LOW: 'Battery Low',
  GAS_LOW: 'Fuel Low',
  EXCEEDED_GOAL_COST: 'Over Budget',
  EXCEEDED_IDLE_TIME: 'Over Idle',
  WORK_IN_PROGRESS: 'Work In Progress',
  SECONDS: 'Seconds',
  NUMBER_SECONDS: 'NUMBER_SECONDS',
  DOLLARS: 'Dollars',
  TIME_GOAL: 'TIME_GOAL',
  COST_GOAL: 'COST_GOAL',
  NUMBER: 'NUMBER',

  /* Dashboard Helper Texts */
  VEHICLES_IN_RECON_HELPER_TEXT:
    'This is the current number of vehicles in your recon process. Work In Progress vehicles are the number of vehicles that are currently assigned to people and those people are clocked in on the vehicle.',
  TIME_GOAL_AVERAGE_HELPER_TEXT:
    'Your Retail-Ready Time is your overall total time it takes to get your inventory ready for sale. This time begins from the moment a vehicle is acquired and stocked in and includes In-Transit time.',
  AVERAGE_RECON_TIME_HELPER_TEXT:
    'Your Recon Time is customizable per step in your settings section and typically excludes In-Transit time and other uncontrollable times by your internal recon team. i.e. salesperson has keys showing vehicle to potential buyer, etc.',
  AVERAGE_APPROVAL_TIME_HELPER_TEXT:
    'The average time it takes to approve a pending task. Tasks typically include recommendations from the MPI and any vendor tasks such as PDR, windshield, wheel repair, etc. that need a manager’s approval.',

  /* Errors */
  INVALID_LOGIN_MESSAGE: 'Invalid login. Please try again',
  EMAIL_API_MESSAGE:
    'There was an error. Please ensure that you have typed in the correct email below.',
  API_MESSAGE: 'There was an API error. Please try again',
  PUSH_NOTIFICATION_ERROR_MESSAGE:
    'There was an error routing to the notification. Please try again',
  ERROR_CHANGING_PASSWORD:
    'Invalid Action. Please ensure you typed correct current password.',
  SUCCESS_CHANGING_PASSWORD: 'Password changed successfully.',
  PROFILE_UPDATE_ERROR: 'There was an error updating user profile.',
  EXISTING_EMAIL_ERROR:
    'The email address provided is already in use with an existing account. Please try again with a different email address',

  /* Vehicle Details */
  VEHICLE_DETAILS_TITLE: 'Vehicle Details',
  VEHICLE_DETAILS_STEP: 'Step',
  ASSIGNEE: 'Assignee',
  VIEW_VEHICLE: 'View Vehicle',
  VIEW_MORE: 'View More',
  NO_YEAR_OR_MAKE: 'No Year or Make',

  /* Header */
  DASHBOARD: 'Dashboard',
  INVENTORY: 'Inventory',
  ACTIVE_RECON: 'Active Recon',
  REPORTS: 'Reports',
  INVOICING: 'Invoicing',
  EDIT_PROFILE: 'Edit Profile',
  CHANGE_PASSWORD: 'Change Password',
  LOGOUT: 'Logout',
  SECTIONS: 'Sections',
  ROOFTOP_OPTIONS: 'Rooftop Options',
  ROOFTOP_SEARCH_PLACE_HOLDER: 'Enter Dealership Name',
  PROFILE_OPTIONS: 'Profile Options',
  CLEAR: 'Clear',
  SUBMIT_FEEDBACK: 'Contact Support',
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  ROOFTOP: 'Rooftop',
  FEEDBACK_SUCCESS: 'Your feedback was submitted successfully. Thank you!',
  UPLOADS_PLURAL: (uploadCount: number) =>
    uploadCount === 1 ? '1 Upload...' : `${uploadCount} Uploads...`,
  COPY_EMAIL_TEMPLATE_SUCCESS: 'Email template copied to clipboard.',
  VELOCITY_ENGAGE_EMAIL_TEMPLATE: 'Copy Email Template',
  VIN_INQUIRY: 'VIN Inquiry',
  ERROR_INVALID_VIN: 'Error: Invalid Vin',
  VEHICLE_NOT_FOUND_ALERT_MESSAGE: 'Vehicle Not Found',
  WINDOW_STICKER_SERVICE_NOT_AVAILABLE:
    'The provider is temporarily unavailable. Please try again later.',
  VELOCITY_ENGAGE_EMAIL_TEMPLATE_DEALERSOCKET: 'Copy Dealersocket Template',
  VELOCITY_ENGAGE_EMAIL_TEMPLATE_DRIVE_CENTRIC: 'Copy Drive Centric Template',
  VELOCITY_ENGAGE_EMAIL_TEMPLATE_ELEAD: 'Copy eLead Template',
  VELOCITY_ENGAGE_EMAIL_TEMPLATE_VIN_SOLUTIONS: 'Copy VIN Solutions Template',
  VELOCITY_INSIGHT_DOWNLOAD: 'Velocity Insight Download',
  VIEW_RELEASE_NOTES: 'View Release Notes',

  /* Vehicle Panel */
  CURRENT_STEP: 'Current Step',
  RECON: 'Recon',
  ENGAGE: 'Engage',
  TOTAL_RECON_TIME: 'Total Recon Time',
  RETAIL_READY_TIME: 'Retail-Ready Time',
  RETAIL_TIME_IN_STEP: 'Retail Time In Step',
  RETAIL_READY_TIME_IN_STEP: 'Retail-Ready Time In Step',
  STEPS_WORKING_TIME: 'Working Time',
  IDLE_TIME: 'Idle Time',
  IDLE: 'Idle',

  /* Common strings */

  AGE: 'Age',
  ALL: 'All',
  MULTIPLE: 'Multiple',
  APPROVE: 'Approve',
  ASSIGNED: 'Assigned   ',
  ALERTS: 'Alerts',
  ACCEPT: 'Accept',
  BACK: 'Back',
  CANCEL: 'Cancel',
  CHANGE_STEP: 'Change Step',
  CHECK_IN: 'Check In',
  CLOSE: 'Close',

  DANGER: 'Danger',
  DELETE: 'Delete',
  DENY: 'Deny',
  EMPTY_VALUE: '--',
  DEFAULT_CURRENCY: '$',
  FILE_TOO_LARGE: 'You can only upload files up to 5MB in size',
  USD: 'USD',
  GOOD: 'Good',
  LOOK_UP: 'Look Up',
  MARK_COMPLETE: 'Mark Complete',
  NOT_ASSIGNED: 'Not Assigned',
  OK: 'Ok',
  RECALLS: 'Recalls',
  SAVE: 'Save',
  SAVE_CHANGES: 'Save Changes',
  SENT: 'SENT',
  STATUS: 'Status',
  STEP: 'Step',
  SHARED_STEP: 'Shared Step',
  SHARED_TENANT: 'Shared DLR',
  STOCK_NUMBER: 'Stock Number',
  SUBMIT: 'Submit',
  SUBMIT_FOR_APPROVAL: 'Submit for Approval',
  TOO_MANY_FILES: 'You can only upload 5 files at a time.',
  TOTAL: 'Total',
  TOTAL_ESTIMATE: 'Total Estimate',
  TOTAL_REQUESTED: 'Total Requested',
  TOTAL_DENIED: 'Total Denied',
  TOTAL_APPROVED_COST: 'Total Approved Cost',
  UNDO: 'Undo',
  WARNING: 'Warning',

  STEPID_FILTER_TITLE: 'Step',
  FILTERS_TITLE: 'Filters',
  FILTERS_TITLE_WITH_COUNT: (count: number) =>
    count > 0 ? `Filters (${count})` : 'Filters',
  HAS_RECALLS: 'Has Recalls',
  DOES_NOT_HAVE_RECALLS: 'Does Not Have Recalls',
  HAS_TASKS: 'Has Tasks',
  DOES_NOT_HAVE_TASKS: 'Does Not Have Tasks',

  /* My Assignments */

  VEHICLES: 'Vehicles',
  NO_VEHICLES_TITLE: 'No vehicles Assigned to you',
  NO_VEHICLES_SUBTITLE: 'Go to vehicles index to browse inventory',
  FETCH_ERROR_TITLE: 'Something went wrong...',
  FETCH_ERROR_SUBTITLE: 'Check your connection and pull to refresh.',
  MY_ASSIGNMENTS: 'My Assignments',

  /* Overview */
  RECON_PROGRESS: 'Recon Progress',
  RECON_STEP_PROGRESS: 'Recon Step Progress',
  PROGRESS: 'Progress',
  TOTAL_TIME: 'Total Time',
  STEP_PROGRESS: 'Step progress',
  SALES_PRICE: 'Sales Price',
  PROFIT: 'Profit',
  WORKING: 'Working',

  /* VehicleDetails */

  ASSIGNED_TO: 'Assigned to',
  ATTACHMENTS_EMPTY: 'No documents found for the current vehicle.',
  IMAGES_EMPTY: 'No images found for the current vehicle.',
  BACK_TO_SRP: 'BACK TO LIST',
  BEGIN_WORK: 'Begin Work',
  CHECKLIST_INCOMPLETE: 'Checklist Incomplete',
  COMPLETE: 'Complete',
  COMPLETE_ALL_REQUIRED: 'Complete all required checklist items to proceed.',
  CONTINUE: 'Continue',
  DAYS_IN_INVENTORY: 'Days in Inv.',
  LOCATION: 'Location',
  INSPECTION: 'Inspection',
  MULTI_POINT_INSPECTION: 'Multi-Point Inspection (MPI)',
  MPI: 'MPI',
  NO_RECON_INFO: 'No recon information.',
  NOTE: 'Note',
  NOTES: 'Notes',
  NOTES_PERMISSION_DENIED: 'Permission Denied',
  NOTES_PERMISSION_DENIED_SUB:
    'You do not have sufficient permission to view notes',
  NOTES_PLACEHOLDER: 'Type a message...',
  OVERVIEW: 'Overview',
  PAUSE: 'Pause Work',
  PAUSE_WORK_QUESTION: 'Pause work?',
  PHOTOS_PERMISSION_DENIED: 'Permission Denied',
  PHOTOS_PERMISSION_DENIED_SUB:
    'You do not have sufficient permission to view photos',
  RECALLS_DESCRIPTION:
    'The following recalls have been found for this vehicle.',
  RECALLS_EMPTY: 'No recalls found for the current vehicle.',
  RECALLS_PERMISSION_DENIED:
    'You do not have sufficient permission to view recalls',
  RECOMMENDED: 'Recommended',
  RECON_GOAL: 'Recon Goal',
  RECON_INFORMATION: 'Recon Information',
  RECON_TIME_IN_STEP: 'Recon Time In Step',
  RECON_VELOCITY: 'Velocity Recon',
  VELOCITY_RECON: 'Velocity Recon',
  SEND: 'SEND',
  SEND_TO_SHOPPER: 'Send To Shopper',
  TASKS: 'Tasks',
  THIS_WILL_STOP_TIMER: 'This will stop your work timer.',
  TIME_IN_STEP: 'Time In Step',
  TOTAL_RECON: 'Total Recon',
  VEHICLE_INFORMATION: 'Vehicle Information',
  VEHICLE_NOT_FOUND: 'Unable to load vehicle details.',
  VEHICLE_SECTIONS_DEFAULT_ICON: 'clipboard_text',
  VEHICLES_TO_SEND: 'Vehicles to Send',
  VELOCITY_LOCATE: 'Velocity Locate',
  VELOCITY_LOCATE_MENU_LABEL: 'Velocity Locate',
  VELOCITY_ENGAGE: 'Velocity Engage',
  WORKING_TIME: 'Working Time',

  /* Recall Items */
  RECALL_TITLE_LABEL: 'NUMBER / TITLE',
  REMEDY: 'Remedy',
  ISSUED: 'Issued',

  /* Documents */
  DOCUMENTS: 'Documents',
  BROWSE: 'Browse',
  MODAL_SUBTITLE:
    'Select a file from your computer to add it to this vehicle’s document library.',
  MODAL_NOTES_SUBTITLE: 'Select a file from your computer to share it.',
  UPLOAD_DOCUMENT: 'Upload Document',
  TIME_IN: 'Time in',

  /* ChangeStep */

  ACTIVE: 'Active',
  ACTIVE_STEP: 'Active Step',
  COMPLETED: 'Completed',
  INCOMPLETE: 'Incomplete',
  PENDING: 'Pending',
  STEP_CHANGE_ERROR: 'Step Change Error',
  CANNOT_MOVE_STEP:
    'Cannot move to {{nextStep}} step before the {{incompleteSteps}} step(s) have been completed.',
  NOT_STARTED: 'Not Started',
  CHANGE_STEP_MODAL_SUBTITLE:
    'Choose the step you would like to place this vehicle into. You can also assign the step to a technician from the list below.',
  INVENTORY_STATUS_MANUALLY_CREATED: 'MANUALLY_CREATED',

  /* Add Vehicle */
  ADD_VEHICLE_MODAL_BODY:
    'Enter the details below to add a vehicle to your inventory.',
  ADD_VEHICLE: 'Add Vehicle',
  NO_VALID_VIN: 'Invalid VIN',
  STOCK_OR_VIN: 'Stock or VIN',
  SCAN_VIN: 'Scan VIN',
  NO_VIN_FOUND: 'No VIN Found',
  PLEASE_TRY_AGAIN: 'Please try again.',
  CAMERA_PERMISSION_TITLE: 'Permission to use camera',
  CAMERA_PERMISSION_MESSAGE: 'We need your permission to use your camera',
  STOCK_NUMBER_REQUIRED: 'Stock Number (Required)',
  STOCK_NUMBER_REQUIRED_HELPER_TEXT: 'Stock Number is required.',
  STOCK_NUMBER_NO_VALID_HELPER_TEXT: 'Invalid Stock Number.',
  SELECT: 'Select ',
  SELECT_ONE: 'Select One',
  COLOR: 'Color',
  RECON_COST: 'Recon Cost',
  EXISTING_STOCK_ERROR: (stockNumber: string) =>
    `Stock number ${stockNumber} already in use:  `,
  VIN_REQUIRED: 'Vehicle Identification Number (Required)',
  VIN_REQUIRED_SHORT: 'VIN (Required)',
  MANUALLY_CREATED: 'MANUALLY_CREATED',

  /* Edit Vehicle*/
  UPDATED_BY_RECON_VELOCITY: 'reconvelocity',

  /* VehicleInfoCard */

  VIN: 'VIN',
  VIN_TOOLTIP: 'Vehicle Identification Number',
  STK: 'STK',
  STK_TOOLTIP: 'Stock Number',
  MIL: 'Mi',
  MIL_SRP_CARD: 'MIL',
  MIL_TOOLTIP: 'Vehicle Mileage',
  DLR: 'DLR',
  DLR_TOOLTIP: 'Dealership Rooftop',
  DIS: 'DIS',
  DIS_TOOLTIP: 'Disposition',
  EXT: 'EXT',
  EXT_TOOLTIP: 'Exterior Color',
  STOCK_NO: 'STOCK #',
  RECON_TIME: 'Recon Time',
  RECON_READY: 'RECON READY',
  RETAIL_READY: 'RETAIL-READY',
  ADDED_DAYS_AGO: 'Added {{days}} Days Ago',
  DRIVETRAIN_LABEL: 'DT',
  DRIVETRAIN_TOOLTIP: 'Drivetrain',
  TRIM_LABEL: 'TRM',
  TRIM_TOOLTIP: 'Trim',

  /* Profile */

  MY_PROFILE: 'My Profile',
  CHANGE_ROOFTOP: 'Change Rooftop',

  /* Vehicle Information */

  VEHICLE_INFO: 'Vehicle Info',
  PHOTOS: 'Photos',
  MEDIA: 'Media',
  MEDIA_AND_DOCS: 'Media & Docs',
  YEAR: 'Year',
  MAKE: 'Make',
  MODEL: 'Model',
  TRIM: 'Trim',
  MILEAGE: 'Mileage',
  MILES: 'Miles',
  MILES_SHORT: 'mi',
  KILOMETERS: 'Kilometers',
  KILOMETERS_SHORT: 'km',
  STOCK: 'Stock',
  RECON_GOAL_TIME: 'Recon Goal Time',
  DISPOSITION: 'Disposition',
  UNKNOWN_OPTION: 'UNKNOWN',
  EDIT_VEHICLE_INFO: 'Edit Vehicle Info',
  FORCE_COMPLETION: 'Force Completion',
  FORCE_COMPLETION_DESCRIPTION:
    'This will complete the vehicle and remove it from recon. All data will continue to be included in reporting.',
  REMOVE_FROM_RECON: 'Remove from Recon',
  REMOVE_FROM_RECON_DESCRIPTION:
    'This will remove the vehicle from recon and delete all reporting data.',
  BACK_TO_RECON: 'Send to Recon',
  BACK_TO_RECON_DESCRIPTION:
    'This will move the vehicle into Reconditioning. All data will continue to be added to reporting. This action will re-start the process if there was a completed workflow or start new if no workflow exists.',
  DELETE_VEHICLE_DESCRIPTION:
    'This will delete the vehicle from recon/inventory and delete all reporting data.',
  PRINT_VDP_INFO: 'Print PDF',
  DRIVETRAIN: 'Drivetrain',

  /* Edit Profile */

  EDIT_PROFILE_TITLE: 'Edit Profile',
  EDIT_PROFILE_MODAL_BODY:
    'The following information can be updated in your profile',
  EDIT_PROFILE_FIRST_NAME: 'First Name',
  EDIT_PROFILE_LAST_NAME: 'Last Name',
  EDIT_PROFILE_EMAIL: 'Email',
  EDIT_PROFILE_PHONE: 'Phone (Optional)',
  EDIT_PROFILE_DEFAULT_ROOFTOP_LOCATION: 'Default Rooftop Location',

  /* ReconInformation */

  RECON_INFO: 'Recon Info',
  APPRAISED_VALUE: 'Appraised Value',
  EST_RECON: 'Est. Recon',
  AVERAGE_ESTIMATED: 'Average Estimated',
  ESTIMATED_RECON: 'Estimated Recon',
  ESTIMATED_RECONDITION: 'Estimated Recondition',
  TARGET_COST: 'Target Cost',
  INITIAL_COST: 'Initial Cost',
  COST: 'Cost',
  PACK: 'Pack',
  ACTUAL_RECON: 'Actual Recon',
  OVERAGE: 'Overage',
  EDIT_RECON_INFO: 'Edit Recon Info',
  TOTAL_OVERAGE: 'Total Overage',
  EDIT_RECON_INFO_MODAL_TITLE: 'Edit Recon Information',
  EDIT_RECON_INFO_MODAL_SUBTITLE:
    'You may update the fields to adjust the recondition information.',
  REQUIRED_FIELDS: 'These fields are',
  AVERAGE_ACTUAL: 'Average Actual',

  /* Tasks */

  APPROVED_TOTAL: 'Approved Total',
  ACCEPTED: 'Accepted',
  ADD_TASK: 'Add Task',
  CLICK_PLUS_ADD_TASK: 'Click the plus (+) button to add a task.',
  EDIT_TASK: 'Edit Task',
  NO_TASKS_HERE: 'No tasks here.',
  NO_TASKS_TO_COMPLETE: 'No tasks to complete.',
  TASK: 'Task',
  TASK_COMPLETED: 'Task completed.',
  TASK_DELETED: 'Task deleted.',
  TASK_NAME: 'Task Name',
  TASK_TYPE: 'Task Type',
  NOTE_OPTIONAL: 'Note (Optional)',
  PENDING_APPROVAL: 'Pending Approval',
  PARTS_COST: 'Parts Cost',
  LABOR_COST: 'Labor Cost',
  LABOR_HOURS: 'Labor Hours',
  LABOR_RATE: 'Labor Rate',
  PRICE: 'Price',
  ADD_EDIT_SUB_TITLE: (isEdit: boolean) => `${
    isEdit ? 'Edit' : 'Enter'
  } the details below for the task you wish to
    ${
      isEdit ? 'update' : 'create'
    }. Some tasks will require approval before work can begin on them and they can be completed.`,
  PARTS: 'Parts',
  LABOR: 'Labor',
  MISC: 'Misc',
  ESTIMATES: 'Estimates',
  REQUESTS: 'Requests',
  TODO: 'To-Do',
  REQUEST_APPROVAL: 'Request',
  COSTS: 'Costs',
  ENTER_DESCRIPTION: 'Enter Description',
  VIEW_APPRAISAL: 'View appraisal info',
  TOTALS: 'Totals',
  APPRAISAL_INFO: 'Appraisal information',
  ESTIMATED_RECON_COST: 'Estimated recon cost',
  BOOK_VALUE: 'Book value',
  CUSTOM_PLACEHOLDER: 'Enter custom task type...',
  TASK_TYPE_PLACEHOLDER: 'Select task type',
  LABOR_RATE_MODAL_TITLE: 'Labor Cost Calculator',
  LABOR_AND_PARTS_REQUIRED: 'Labor and Parts fields are required.',
  FILL_LABOR_COST: 'Fill labor cost',
  RATE: 'Rate',
  HOURS: 'Hours',
  SELECTED_TASKS: 'Selected Tasks',

  /* Task Status */

  STATUS_ESTIMATED: 'Estimated',
  STATUS_REQUESTED: 'Requested',
  STATUS_APPROVED: 'Approved',
  STATUS_COMPLETED: 'Completed',
  STATUS_DENIED: 'Denied',
  STATUS_DELETED: 'Deleted',
  INVOICED: 'Invoiced',
  PAID: 'Paid',

  /* Notes */

  NO_NOTES: 'No Notes.',
  NO_NOTES_MESSAGE:
    'When a note is shared about this vehicle, it will appear here.',
  EDIT_NOTE_TITLE: 'Edit Note',
  NOTE_ASTERISK: 'Note*',
  DELETE_ATTACHMENT: 'Delete Attachment',
  /*Carfax */
  CARFAX: 'Carfax',
  CARFAX_LOGOUT: 'Logout',

  /* History */

  HISTORY: 'History',
  NO_HISTORY: 'No History.',
  NO_HISTORY_MESSAGE: 'When a vehicle has a change, it will appear here.',
  NO_CURRENT_HISTORY_MESSAGE:
    'This vehicle has no history items. When a vehicle has a change, it will appear here.',
  VEHICLE_LOG: 'Vehicle Log',
  STEP_SUMMARY: 'Step Summary',

  /* Location */

  VEHICLE_LOCATION_NOT_FOUND: 'Vehicle Location not found',
  SCAN_VIN_SET_LOCATION:
    'Scan the VIN or Barcode to set the location of the vehicle.',
  COULD_NOT_ACCESS_LOCATION: 'Could not access location.',
  NO_LOCATION_SET: 'No location set.',
  SET_CURRENT_LOCATION_CLICK_BELOW:
    'To set the location of this vehicle to your current location, click the button below.',
  USE_MY_LOCATION: 'Use My Location',
  LOCATION_EMPTY_SCREEN: 'No location found for the current vehicle.',

  /* Recon Vehicles */

  VELOCITY_LOCATE_BANNER_TEXT: 'Manage your fleet with',
  MANAGE_FLEET: 'Manage Fleet',
  RECON_VEHICLES: 'Recon Vehicles',
  SEARCH_PLACEHOLDER: 'Search vehicles...',
  SORT_OPTIONS: 'Sort by',
  FILTER_BY_STEP: 'Filter by Step',
  RECON_COMPLETE: 'Recon Complete',
  VEHICLE_COUNT: (count: number) => `${count} Vehicle${count !== 1 ? 's' : ''}`,
  SHOWROOM_MODE: 'Showroom',
  SEARCH_INVENTORY: 'Search... (ex. year, make, model, stock, VIN)',
  SEARCH_INVOICING: 'Search... (ex. invoice #, year, make, model, stock, VIN)',

  /* Tasks */

  DENIED: 'Denied',
  TASK_ACCEPTED: 'Task accepted.',
  TASK_DENIED: 'Task denied.',
  PENDING_COMPLETION: 'Pending Completion',

  /* Task Type */

  SYNDICATION: 'Syndication',
  SYNDICATION_HEADER_DESCRIPTION_BEFORE_ICON:
    'The images below have been assigned to these angles' +
    ' and will be used for syndication. Click ',
  SYNDICATION_HEADER_DESCRIPTION_AFTER_ICON:
    ' to select an image from the gallery. You will be given' +
    ' the option to upload an additional image if you do not like the options from the gallery.',
  GALLERY: 'Gallery',
  GALLERY_WITH_COUNT: (count: number) => `Gallery (${count})`,
  SYNDICATION_WITH_COUNT: (count: number) => `Syndication (${count})`,
  TYPE_CUSTOM: 'Custom',
  UPLOAD: 'Upload',
  UPLOAD_PICTURE: 'Upload Picture',
  UPLOAD_PICTURE_SUB_TITLE:
    'Select a picture from your computer to add an image to this vehicles photo library.',
  TAKE_A_PHOTO: 'Take a Photo',
  CHOOSE_A_PHOTO: 'Choose a Photo',
  UPLOADED_PHOTOS: 'Uploaded Photos',
  RETAKE_A_PHOTO: 'Retake a Photo',
  REPLACE_FROM_LIBRARY: 'Replace from Library',
  REPLACE_FROM_UNASSIGNED: 'Replace from Unassigned',
  UPLOAD_IMAGE: 'Upload Image',
  UPLOAD_IMAGE_SUB_TITLE:
    'Select an image from your computer to add it to this task.',
  RETAKE: 'Retake',
  CHOOSE: 'Choose',
  UNASSIGN: 'Unassign',
  UNASSIGN_USER: 'Unassign User',
  UNKNOWN_LABEL: 'Unknown',
  UNASSIGNED: 'Unassigned',
  ASSIGN_ANGLE: 'Assign Angle',
  ASSIGN_TO: 'Assign to',
  DEALER_IMAGE_TYPE: 'DEALER',

  /* Location */
  NO_ACCESS_TO_SET_CURRENT_LOCATION_CLICK_BELOW:
    'Contact an administrator to set the location of this vehicle.',

  /* Recon Vehicles */

  DANGER_FILTER: 'DANGER',
  END_VEHICLE_LIST: 'End of Vehicle List',
  FILTER_BY_STATUS: 'Status',
  NO_SEARCH_RESULTS: 'No vehicles found!',
  NO_SEARCH_SUBTITLE: 'We were unable to find any vehicles.\nPlease try again.',
  WARNING_FILTER: 'WARNING',

  /* Multi-Point Inspection */
  TEXT_INPUT_PLACEHOLDER: 'Type here...',
  VEHICLE_INSPECTION_POINTS: 'Vehicle Inspection Points',
  CONTACT_ADMIN_MESSAGE: 'Please contact your admin to setup.',
  QUESTIONS: 'Questions',

  /* Syndication */
  SYNDICATION_MODAL_TITLE: (label: string) => `Assign Photo to "${label}"`,
  SYNDICATION_MODAL_SUBTITLE: 'or choose from the gallery.',
  SELECT_FILE: 'SELECT FILE',
  ASSIGN: 'Assign',

  /* Angle Id */
  ANGLE_UNKNOWN: 'UNKNOWN',
  NO_MORE_NOTIFICATIONS: 'No more notifications.',
  NO_NOTIFICATIONS_TITLE: 'You have no notifications.',
  NO_NOTIFICATIONS_SUBTITLE: 'Go to the vehicle index to browse inventory.',

  /* ImageViewer */
  REPLACE_IMAGE_TITLE: 'Replace this image?',
  REPLACE_IMAGE_SUP_TEXT: 'The previous image will be unassigned.',
  UNASSIGN_TITLE: 'Delete this image?',
  UNASSIGN_SUP_TEXT: 'This action cannot be undone.',

  /* Notifications */

  NOTIFICATIONS: 'Notifications',
  DELETE_IMAGE_TITLE: 'Delete this image?',
  DELETE_IMAGE_SUP_TEXT: 'This action cannot be undone.',
  DELETE_ALL_NOTIFICATIONS_TITLE:
    'Are you sure you want to delete all notifications?',
  DELETE_ALL_NOTIFICATIONS_SUBTITLE: 'This action cannot be undone.',
  NOTIFICATIONS_DELETED: 'Notifications deleted.',
  TARGET_TYPE_ATTACHMENT: 'ATTACHMENT',
  TARGET_TYPE_LOCATION: 'LOCATION',
  TARGET_TYPE_NOTE: 'NOTE',
  TARGET_TYPE_PHOTO: 'PHOTO',
  TARGET_TYPE_STEP: 'STEP',
  TARGET_TYPE_GOAL: 'GOAL',
  TARGET_TYPE_ENGAGEMENT: 'ENGAGEMENT',
  TARGET_TYPE_TASK: 'TASK',
  TARGET_TYPE_UNKNOWN: 'UNKNOWN',
  TARGET_TYPE_VEHICLE: 'VEHICLE',

  /* Date */
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  CUSTOM_RANGE: 'Custom Range',

  /* Edit Disposition */
  EDIT_DISPOSITION_TITLE: 'Edit Vehicle Information',
  EDIT_DISPOSITION_DESCRIPTION:
    'You may update the fields below to adjust the recondition information.' +
    ' These fields are required.',
  EDIT_DISPOSITION_TAGS_FIELD_TITLE: 'Tags',
  EDIT_DISPOSITION_DISPOSITION_FIELD_TITLE: 'Disposition',
  EDIT_DISPOSITION_COLOR_FIELD_TITLE: 'Color',

  /* Reports */
  DEALER_STEP_REPORT: 'Dealer Step Report',
  CORPORATE_REPORT: 'Corporate Report',
  VEHICLES_COMPLETED: 'Vehicles Completed',
  COMPLETED_VEHICLE: 'Completed Vehicle',
  COMPLETED_VEHICLES: 'Completed Vehicles',
  AVERAGE_RECON_TIME: 'Average Recon Time',
  AVG_RECON_TIME: 'Avg. Recon Time',
  AVERAGE_RECON_TIME_IN_STEP: 'Average Recon Time In Step',
  AVERAGE_ACTUAL_RECON_COST: 'Avg. Actual Recon Cost',
  AVERAGE_IDLE_TIME: 'Average Idle Time',
  GOAL: 'Goal',
  WORK_TIME: 'Work Time',
  STEP_GOAL: 'Step Goal',
  STEP_TIME: 'Step Time',
  LAST_7_DAYS: 'Last 7 Days',
  LAST_30_DAYS: 'Last 30 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  RECONDITION_EFFORT: 'Recondition Effort',
  RECONDITION_COSTS: 'Recondition Costs',
  RETAIL_TIME: 'Retail Time',
  WORKED: 'Worked',
  ESTIMATED: 'Estimated',
  ACTUAL: 'Actual',
  NO_COMPLETED_DATA: 'No Completed Vehicles for this step.',
  DEALERSHIP_SUMMARY: 'Dealership Summary',
  AVERAGE_RETAIL_READY_TIME: 'Average Retail-Ready Time',
  AVG_RETAIL_READY_TIME: 'Avg. Retail-Ready Time',
  AVERAGE_RETAIL_READY_TIME_IN_STEP: 'Average Retail-Ready Time In Step',
  AVERAGE_OVERAGE: 'Average Overage',
  TOTAL_COMPLETED: 'Total Completed',
  ORGANIZATION_SUMMARY: 'Organization Summary',
  FILTER_BY_TAG: 'Filter by Tags: ',
  FILTER_BY_CONDITION: 'Filter by Condition: ',

  /* Velocity Engage corporate report */
  VECR_ACTIONS: 'ACTIONS',
  VECR_APPOINTMENTS: 'APPOINTMENTS',
  VECR_EMAILS: 'EMAILS',
  VECR_ENGAGEMENTS: 'ENGAGEMENTS',
  VECR_FOLIO: 'FOLIO',
  VECR_PRICE_CHANGE_ALERTS: 'PRICE CHANGES',
  VECR_SOLD: 'SOLD',
  VECR_VIEW_TIME: 'VIEW TIME',
  ENGAGE_CORPORATE_REPORT: 'Engage Corporate Report',
  VECR_ROOFTOP_NOT_ENABLED: 'Velocity Engage is not enabled for this rooftop',

  /* Edit Tags Modal */
  EDIT_TAGS_MODAL_TITLE: 'Edit Tags',
  EDIT_TAGS_DROPDOWN_LABEL: 'Tags',

  /* Create Tags Modal */
  CREATE_TAG_MODAL_TITLE: 'Create Tag',
  CREATE_TAG_MODAL_TEXT_LABEL: 'Tag Text',
  CREATE_TAG_MODAL_COLOR_LABEL: 'Color',
  CREATE_TAG_MODAL_CREATE_BUTTON: 'CREATE',
  SOLD_VEHICLE_TAG: 'Not-In-Feed',
  TAG_TYPE_DEALER: 'DEALER',
  TAG_TYPE_SYSTEM: 'SYSTEM',

  /* Disposition Modal */
  TITLE_REMOVE: 'Are you sure you would like to remove this vehicle?',
  TITLE_COMPLETE: 'Are you sure you would like to complete reconditioning?',
  TITLE_BACK_TO_RECON:
    'Are you sure you would like to move back to reconditioning?',
  TITLE_DELETE: 'Are you sure you would like to delete this vehicle?',
  DESCRIPTION_REMOVE:
    'The vehicle will be removed from reconditioning. All data will not be included in reporting.',
  DESCRIPTION_COMPLETE:
    'The vehicle will be marked complete and removed from reconditioning. All data will continue to be included in reporting.',
  DESCRIPTION_BACK_TO_RECON:
    'The vehicle will be sent to reconditioning. All data will continue to be included in reporting.',
  DESCRIPTION_DELETE:
    'The vehicle will be removed from reconditioning. All data will not be included in reporting.',
  BUTTON_TITLE_REMOVE: 'REMOVE',
  BUTTON_TITLE_COMPLETE: 'COMPLETE',
  BUTTON_TITLE_SEND: 'SEND',
  BUTTON_TITLE_SEND_TO_RECON: 'SEND TO RECON',
  BUTTON_TITLE_DELETE: 'DELETE',
  DROPDOWN_TITLE_SEND_TO_RECON: 'Send to Recon',
  DROPDOWN_TITLE_DELETE: 'Delete Vehicle',
  DROPDOWN_TITLE_EDIT_VEHICLE: 'Edit',

  /* Media & Documents */
  DELETE_DOCUMENT_TITLE: 'Are you sure want to delete this document?',
  DELETE_DOCUMENT_BODY: 'This action cannot be undone.',
  DELETE_IMAGE_MODAL_TITLE: 'Are you sure you want to delete this photo?',
  DELETE_IMAGE_MODAL_CONFIRM: 'Yes, delete',
  DELETE_IMAGE_MODAL_CANCEL: 'No, cancel',
  GALLERY_SYNDICATION_MODAL_TITLE: 'Assign Photo to Syndication View',
  GALLERY_SYNDICATION_MODAL_PLACEHOLDER: 'Select Syndication View',

  /* Dealership Settings */
  SETTINGS: 'Settings',
  GENERAL: 'General',
  GENERAL_SETTINGS: 'General Settings',
  ENGAGE_SETTINGS: 'Engage Settings',
  LOCATE_SETTINGS: 'Locate Settings',

  /* Onboarding Banner */
  ONBOARDING_BANNER_TITLE: 'Onboarding flag is currently set to TRUE',
  ONBOARDING_BANNER_SUBTITLE:
    'With this setting set to TRUE all ingested vehicles will be placed into Inventory and not into Active Recon',

  /* Velocity Engage */
  USER_ACTIONS: 'User Actions',
  FEATURED: 'Featured',
  VEHICLE_REPUTATION: 'Vehicle Reputation',
  DEALERSHIP_REPUTATION: 'Dealership Reputation',
  FINANCIAL_TOOLS: 'Financial Tools',
  SHOPPERS: 'Shoppers',
  PORTFOLIOS_SENT: 'Portfolios Sent',
  PORTFOLIO_OPENS: 'Portfolio Opens',
  OPEN_RATE: 'Open Rate',
  ENGAGEMENTS: 'Engagements',
  ENGAGEMENTS_PLURAL: (count: number) =>
    count === 1 ? 'Engagement' : 'Engagements',
  AVG_ENGAGEMENT_TIME: 'Avg. Engagement Time',
  AVG_ENGAGEMENT_TIME_SHORT: 'Avg. Eng. Time',
  PRICE_DROP_ALERTS: 'Price Drop Alerts',
  OPENED: 'OPENED',
  PRICE_CHANGE_HISTORY: 'Price Change History',
  VIEW_PRICE_HISTORY: 'View Price History',
  NEW_PRICE: 'New Price',
  START_PRICE: 'Start Price',
  DATE: 'Date',
  MOST_ENGAGED_SHOPPERS: 'Most Engaged Shoppers',
  NAME: 'Name',
  ACTIONS: 'Actions',
  LAST_ACTIVE: 'Last Active',
  OPENS: 'Opens',
  NO_SALES_USERS: 'No users available',
  SELECT_FROM_PLACEHOLDER: 'Select User to Send From',
  SELECT_TO_PLACEHOLDER: 'Select Shopper(s) to Send To',
  SELECT_MODULE_PLACEHOLDER: 'Select Module(s) to Send',
  NO_OPTIONS_FROM: 'No users match',
  NO_OPTIONS_TO: 'No shoppers match',
  NO_OPTIONS_MODULE: 'No modules selected',
  ADD_SHOPPER: 'Add Shopper',
  SHOW_PRICE: 'Show Price',
  SEND_VIA_EMAIL: 'Send via Email',
  SEND_EMAIL: 'Send Email',
  SEND_VIA_TEXT: 'Send via Text',
  SEND_TEXT: 'Send Text',
  COPY: 'Copy',
  UNABLE_TO_COPY: 'Unable to Copy, there was a problem.',
  COPIED_SHARE_LINK: 'Copied share link to clipboard',
  SHARE_LINK: 'Share Link',
  ALL_TIME: 'All Time',
  WEEK_TO_DATE: 'Week to Date',
  TWO_WEEKS_TO_DATE: 'Two Weeks to Date',
  MONTH_TO_DATE: 'Month to Date',
  YEAR_TO_DATE: 'Year to Date',
  ALL_USERS: 'All Salespeople',
  ALL_SHOPPERS: 'All Customers',
  ALL_METHODS: 'All Methods',
  TEXT: 'Text',
  QR_SCAN: 'QR Scan',
  PRINT_ALL: 'Print All',
  PRINT_SUMMARY: 'Print Summary',
  DO_NOT_GROUP: 'Do Not Group',
  GROUP_BY_USER: 'Group by Salesperson',
  GROUP_BY_SHOPPER: 'Group by Customer',
  DATE_AND_TIME: 'Date & Time',
  USER: 'Salesperson',
  SHOPPER: 'Customer',
  METHOD: 'Method',
  CRM_EMAILS_SENT: 'CRM Emails Sent',
  CVDP_OPENS: 'Opens',
  SHOPPER_ACTIONS: 'Actions',
  TOTAL_DURATION: 'View Time',
  ENGAGE_HEATMAP_ITEM_DURATION: 'Duration',
  ENGAGE_HEATMAP_ITEM_AVERAGE_DURATION: 'Average Duration',
  LAST_OPEN: 'Last Open',
  CLICK_FOR_INSIGHTS: 'Click a row to see shopper insights.',
  CLEAR_FILTERS: 'Clear Filters',
  NO_STATE_OPTION: 'No states match',
  SELECT_STATE_PLACEHOLDER: 'Select a State',
  SHOPPER_FIRST_NAME: 'First Name',
  SHOPPER_LAST_NAME: 'Last Name',
  EDIT_SHOPPER_DETAILS: 'Edit Shopper Details',
  CONTACT_DETAILS: 'Contact Details',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ADDRESS: 'Address',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
  CITY: 'City',
  STATE: 'State',
  ZIP: 'Zip',
  VIEW: 'View',
  HEATMAP: 'Heatmap',
  VIEW_HEATMAP: 'View Heatmap',
  VIEW_HEAT_MAP: 'View Heat Map',
  VISITS: 'Visits',
  VIEWS: 'Views',
  SUBMISSIONS: 'Submissions',
  VIEWING: 'Viewing',
  MAX_SELECT_WARNING_MESSAGE:
    'No more than 3 vehicles may be selected at a time',
  ALL_VEHICLES: 'All Vehicles',
  SHOWROOM_ENGAGE_ADVERT:
    'Showcase your recon work to customers interested in your used cars and add instant' +
    ' value and gross with VelocityEngage! Please contact us at info@velocityautomotive.com or call 850-669-5025' +
    ' to get more information and details!',
  NO_ENGAGED_SHOPPERS: 'No Engaged Customers',
  NO_OVERVIEW_OPENS: 'No Overview Opens',
  ADD_MEDIA: 'Add Media',
  CANT_DELETE_ERROR: (title: string) =>
    `Cannot delete${title ? ` ${title}` : ''} media`,
  NO_DOCUMENT_URL: 'This document does not have an associated media url',
  CHANGE_MEDIA_URL: 'Edit Media',
  ENGAGE_DELETE_CONFIRMATION_MESSAGE: 'Delete document from this vehicle?',
  NO_ENGAGE_MEDIA: 'No Velocity Engage media for this vehicle',
  NO_ENGAGE_MEDIA_CONTAINER: (containerTitle: string) =>
    `No ${containerTitle} media`,
  TOO_MANY_FEATURED_DOCS: 'You can only feature 4 documents',
  VIEW_CVDP_ERROR_MESSAGE:
    'A template for this portfolio is not setup. Please update the Velocity Engage templates.',
  FILTER: 'Filter',
  CERTIFIED_PREOWNED_CHIP: 'CERTIFIED PRE-OWNED',
  CLICKS: 'Clicks',
  ENGAGE_HEATMAP_ITEM_TYPE: 'Actions',
  ENGAGE_MEDIA_FETCHING: 'Fetching media...',
  ENGAGE_MEDIA_FETCH_FAILED: 'Could not find media document',
  ENGAGE_MEDIA_RETRYING: 'Media is taking longer than expected to load',

  /* Repair Order */
  REPAIR_ORDER: 'Repair Order',
  REPAIR_ORDERS: 'Repair Orders',
  REPAIR_ORDER_EMPTY: 'No repair orders found for the current vehicle.',
  REPAIR_ORDER_PERMISSION_DENIED:
    'You do not have sufficient permission to view repair orders',
  REPAIR_ORDER_TAG_NUMBER: 'Tag Number',
  REPAIR_ORDER_ADVISOR: 'Advisor',
  REPAIR_ORDER_ACTUAL_HOURS: 'Actual Hours',
  REPAIR_ORDER_SOLD_HOURS: 'Sold Hours',
  REPAIR_ORDER_CUSTOMER: 'Customer',
  REPAIR_ORDER_INTERNAL: 'Internal',
  REPAIR_ORDER_WARRANTY: 'Warranty',

  /* Invoicing */
  CREATE_INVOICE: 'Create Invoice',
  PENDING_INVOICES: 'Pending Invoices',
  PAID_INVOICES: 'Paid Invoices',
  VIEW_INVOICE: 'View Invoice',
  SEND_INVOICE: 'Send Invoice',
  PAY_INVOICE: 'Pay Invoice',
};

export default strings;

import { RouteComponentProps } from '@reach/router';

import { permissions } from 'common';
import { useFeatures, usePermissions } from 'hooks';

import Header from './Header';
import VehicleList from './VehicleList/VehicleList';

import './Inventory.scss';

type Props = RouteComponentProps;

const Inventory = ({ navigate, ...props }: Props) => {
  const { hasFeature } = useFeatures();
  const { hasPermission } = usePermissions();
  const hasVendorFeature = hasFeature('VELOCITY_VENDOR');

  if (!hasPermission(permissions.INVOICING_VIEW) || !hasVendorFeature) {
    // User is using a vendor rooftop without the vendor feature flag
    return (
      <div className="vendor-permission-container">
        <span className="vendor-permission full-height full-width flex-rows">
          You don't have the required permissions to view this page. Please
          contact your administrator.
        </span>
      </div>
    );
  }

  return (
    <div className="vendor-inventory-list">
      <main className="vendor-inventory-list-content">
        <div className="vendor-inventory-list-container">
          <Header />
          <VehicleList />
        </div>
      </main>
    </div>
  );
};

export default Inventory;

import { useMutation, useQuery } from '@tanstack/react-query';

import { APIResponse } from 'api';
import { defaultMutationFn } from 'api/useAPI';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import { DecodedVin, Location, TaskTemplate, UIFilter, User } from 'models';
import { VehicleTag } from 'models/inventory/vehicleTag';

export function useUsers() {
  const path = '/utility/users';
  return useQuery<APIResponse<User[]>>([path]);
}

export function useGetVehicleColors() {
  const { hasPermission } = usePermissions();
  const path = '/utility/vehicle/colors';
  return useQuery<string[]>([path], {
    enabled: hasPermission(permissions.INVENTORY_VIEW),
  });
}

export function useGetVehicleDispositions() {
  const { hasPermission } = usePermissions();
  const path = '/utility/vehicle/dispositions';
  return useQuery<string[]>([path], {
    enabled: hasPermission(permissions.INVENTORY_VIEW),
  });
}

export default function useGetReconUsers() {
  const path = '/utility/users';
  return useQuery<APIResponse<User[]>>([path]);
}

export function useInventoryTags() {
  const path = '/utility/tags';
  return useQuery<VehicleTag[]>([path]);
}

/**
 * @package api/utility
 * @description - Get task templates as TaskTemplate[]
 */
export function useTaskTemplates() {
  const path = `/utility/tasks/templates`;
  return useQuery<APIResponse<TaskTemplate[]>>([path]);
}

export function useGetVehicleImagesShots() {
  const path = `/utility/vehicle/imageshots`;
  return useQuery<{ name: string; value: string }[]>([path]);
}

export function useSubmitFeedback() {
  return useMutation(
    (data: {
      subject: string;
      message: string;
      userName: string;
      userEmail: string;
      rooftop: string;
      userPhone?: string;
    }) => {
      const path = '/profile/feedback';
      return defaultMutationFn(path, 'POST', data);
    }
  );
}

export function useInventoryFilters() {
  const path = `/utility/inventory/filters`;
  return useQuery<UIFilter[]>([path]);
}

export function useDecodeVin(vin: string, enabled: boolean) {
  const path = `/utility/vindecode/${vin}`;
  return useQuery<DecodedVin>([path], { enabled: enabled });
}

export function useVehicleYears() {
  const path = '/utility/vehicle/years';
  return useQuery<number[]>([path]);
}

export function useVehicleMakesByYear(year: number | undefined) {
  const path = `/utility/vehicle/makes/${year}`;
  return useQuery<{ id: string; name: string; year: number }[]>([path], {
    enabled: !!year,
  });
}

export function useVehicleModelsByYearAndMake(
  year: number | undefined,
  makeId: string | undefined
) {
  const path = `/utility/vehicle/models/${year}/${makeId}`;
  return useQuery<
    {
      make: { id: string; name: string; year: number };
      id: string;
      name: string;
    }[]
  >([path], {
    enabled: !!(year && makeId),
  });
}

export function useSharedSteps() {
  const { hasPermission } = usePermissions();
  const path = '/utility/shared/steps';
  return useQuery<APIResponse<any>>([path], {
    enabled: hasPermission(permissions.RECON_VIEW),
  });
}

export function useDealerVendors() {
  const { hasPermission } = usePermissions();
  const path = '/utility/dealer/vendors';
  return useQuery<APIResponse<Location[]>>([path], {
    enabled: hasPermission(permissions.INVOICING_VIEW),
  });
}

import { useQuery } from '@tanstack/react-query';

import { APIResponse, defaultMetaQueryFn } from 'api/useAPI';
import { VehicleSummary } from 'models';

import { getInventoryQueryString } from '.';

/**
 * @package api/vendor-inventory
 * @description - Get summaries for vehicles with vendor tasks
 */
export function useGetVendorInventoryQuery(enabled = false) {
  const queryKey = getVendorInventoryQueryKey();
  return useQuery<APIResponse<VehicleSummary[]>>(
    queryKey,
    () => defaultMetaQueryFn(`${queryKey[0]}?${queryKey[1]}`),
    {
      enabled,
    }
  );
}

export const getVendorInventoryQueryKey = () => {
  const path = '/vendor-inventory';
  const queryString = getInventoryQueryString();
  return [path, queryString];
};

export default useGetVendorInventoryQuery;

import { useLocation } from '@reach/router';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';

import { APIResponse, defaultMetaQueryFn, defaultMutationFn } from 'api';
import {
  ApiResponse,
  Checklist,
  HistoryItem,
  Note,
  Recall,
  VehicleCard,
  VehicleImage,
  VehicleRepairOrder,
  VehicleSummary,
} from 'models';
import { CheckListInstance } from 'models/inventory/checklist';
import { CreateVehiclePayload } from 'models/inventory/CreateVehicle';
import InventoryLocation from 'models/inventory/location';
import { VehicleAttachment } from 'models/inventory/vehicleAttachment';
import { VehicleTag } from 'models/inventory/vehicleTag';

import {
  getAllQueryParamsWithName,
  getLastQueryParamWithName,
} from '../../navigation/util/ParamHelpers';

/**
 * @package api/useVehicle
 * @description - Get history as HistoryItem[] for specific inventory
 * @param id vehicle UUID
 */
type HistoryResponse = { items: HistoryItem[] };
export function useVehicleHistory(id: string) {
  const path = `/inventory/${id}/history`;
  return useQuery<APIResponse<HistoryResponse>>([path], {
    enabled: !!id,
  });
}

/**
 * @package api/inventory
 * @description - Get notes collection as Note[] for specific inventory
 * @param id vehicle UUID
 */
export function useVehicleNotes(id: string) {
  const path = `/inventory/${id}/notes`;
  return useQuery<APIResponse<Note[]>>([path], {
    enabled: !!id,
  });
}

/**
 * @package api/inventory
 * @description - Get recall information as Recall[] for specific inventory
 * @param id vehicle UUID
 */
export function useVehicleRecalls(id: string) {
  const path = `/inventory/${id}/recalls`;
  return useQuery<APIResponse<Recall[]>>([path], {
    enabled: !!id,
  });
}

/**
 * @package api/inventory/{vehicleId}/location
 * @description - Get location as GeoLocation for specific inventory
 * @param id vehicle UUID
 */
export function useVehicleLocation(id: string) {
  const path = `/inventory/${id}/location`;
  return useQuery<APIResponse<InventoryLocation[]>>([path], {
    enabled: !!id,
  });
}

/**
 * @package api/inventory/{vehicleId}/plugins/vdp/checklists
 * @description - Get checklists for specific inventory
 * @param id vehicle UUID
 */
export function useVehicleChecklists(vehicleId: string) {
  const path = `/inventory/${vehicleId}/plugins/vdp/checklists`;
  return useQuery<APIResponse<Checklist[]>>([path], {
    enabled: !!vehicleId,
  });
}

/**
 * @package api/inventory/{vehicleId}/plugins/vdp/checklists/{checklistId}
 * @description - Get checklist for specific inventory
 * @param vehicleId vehicle UUID
 * @param checklistId checklist UUID
 */
export function useVehicleChecklist(vehicleId: string, checklistId: string) {
  const path = `/inventory/${vehicleId}/plugins/vdp/checklists/${checklistId}`;
  return useQuery<APIResponse<CheckListInstance>>([path], {
    enabled: !!vehicleId && !!checklistId,
  });
}

/**
 * @package api/inventory/{vehicleId}/plugins/vdp/checklists/{checklistId}
 * @description - update answers for checklist for specific inventory
 * @param vehicleId vehicle UUID
 * @param checklistId checklist UUID
 */
export function useUpdateVehicleChecklist(
  vehicleId: string,
  checklistId: string
) {
  const queryClient = useQueryClient();
  const path = `/inventory/${vehicleId}/plugins/vdp/checklists/${checklistId}`;
  return useMutation((data) => defaultMutationFn(path, 'PUT', data), {
    onMutate: async (answers: any) => {
      await queryClient.cancelQueries([path]);
      const previousValue: any = queryClient.getQueryData([path]);

      queryClient.setQueryData([path], (data: any) => {
        data.data.answers = answers;
        return data;
      });

      return previousValue;
    },
    onError: () => {
      queryClient.invalidateQueries([path]);
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        `/inventory/${vehicleId}/plugins/vdp/checklists`,
      ]);
    },
  });
}

/**
 * @package api/inventory
 * @description - Get summary for a vehicle
 * @param id vehicle UUID
 */
export function useVehicleSummary(id: string | undefined) {
  const path = `/inventory/${id}`;
  return useQuery<APIResponse<VehicleSummary>>([path], {
    enabled: !!id,
  });
}

export interface InventoryQuerySearchParams {
  sort?: string;
  sortDirection?: string;
  search?: string;
  filters: {
    assignee?: string;
    stepId?: string;
    disposition?: string;
    area?: string;
    status?: string;
    age?: string;
    condition?: string;
    tag?: string;
    recalls?: string;
    inventoryState?: string;
    tasks?: string;
    color?: string;
    year?: string;
    retailPrice?: string;
    bodyType?: string;
    make?: string;
    model?: string;
    mileage?: string;
  };
}

export const FILTER_PARAM_NAMES = [
  'age',
  'area',
  'assignee',
  'bodyType',
  'condition',
  'color',
  'disposition',
  'inventoryState',
  'make',
  'model',
  'mileage',
  'recalls',
  'retailPrice',
  'status',
  'stepId',
  'tag',
  'tasks',
  'year',
] as const;

const filterGetters = {
  age: () => {
    const queryParam = getLastQueryParamWithName('age') || '';
    return convertUiFilterToApiFilter(queryParam, `AGE`);
  },
  area: () => {
    const queryParam = getLastQueryParamWithName('area') || '';
    return convertUiFilterToApiFilter(queryParam, 'AREA');
  },
  assignee: () => {
    const queryParam = getLastQueryParamWithName('assignee') || '';
    return convertUiFilterToApiFilter(queryParam, 'ASSIGNEE');
  },
  bodyType: () => {
    const queryParam = getAllQueryParamsWithName('bodyType') || [];
    return convertUiFilterToApiFilter(queryParam, 'VEHICLE_BODY_TYPE');
  },
  condition: () => {
    const queryParam = getAllQueryParamsWithName('condition') || [];
    return convertUiFilterToApiFilter(queryParam, 'CONDITION');
  },
  color: () => {
    const queryParam = getAllQueryParamsWithName('color') || [];
    return convertUiFilterToApiFilter(queryParam, 'INVENTORY_COLOR');
  },
  disposition: () => {
    const queryParam = getAllQueryParamsWithName('disposition') || [];
    return convertUiFilterToApiFilter(queryParam, 'DISPOSITION');
  },
  inventoryState: () => {
    const queryParam = getLastQueryParamWithName('inventoryState') || '';
    return convertUiFilterToApiFilter(
      queryParam,
      'INVENTORY_STATE',
      workflowInventoryStatesUiToApiMap
    );
  },
  make: () => {
    const queryParam = getAllQueryParamsWithName('make') || [];
    return convertUiFilterToApiFilter(queryParam, 'VEHICLE_MAKE');
  },
  model: () => {
    const queryParam = getAllQueryParamsWithName('model') || [];
    return convertUiFilterToApiFilter(queryParam, 'VEHICLE_MODEL');
  },
  mileage: () => {
    const queryParam = getLastQueryParamWithName('mileage') || '';
    return convertUiFilterToApiFilter(queryParam, 'INVENTORY_MILEAGE');
  },
  recalls: () => {
    const queryParam = getLastQueryParamWithName('recalls') || '';
    return convertUiFilterToApiFilter(queryParam, 'RECALLS');
  },
  retailPrice: () => {
    const queryParam = getLastQueryParamWithName('retailPrice') || '';
    return convertUiFilterToApiFilter(queryParam, 'INVENTORY_RETAIL_PRICE');
  },
  status: () => {
    const queryParam = getLastQueryParamWithName('status') || '';
    return convertUiFilterToApiFilter(
      queryParam,
      'STEP_STATUS',
      workflowStatusesUiToApiMap
    );
  },
  stepId: () => {
    const queryParam = getLastQueryParamWithName('stepId') || '';
    return convertUiFilterToApiFilter(queryParam, 'STEP');
  },
  tag: () => {
    const queryParam = getAllQueryParamsWithName('tag') || [];
    return convertUiFilterToApiFilter(queryParam, 'TAG');
  },
  tasks: () => {
    const queryParam = getLastQueryParamWithName('tasks') || '';
    return convertUiFilterToApiFilter(queryParam, 'TASKS');
  },
  year: () => {
    const queryParam = getLastQueryParamWithName('year') || '';
    return convertUiFilterToApiFilter(queryParam, 'VEHICLE_YEAR');
  },
};

const workflowStatusesUiToApiMap: Record<string, string> = {
  OK: 'ONTIME',
  WARNING: 'WARNING',
  DANGER: 'LATE',
  UNKNOWN: 'UNKNOWN',
  ALL: 'ALL',
};

const workflowInventoryStatesUiToApiMap: Record<string, string> = {
  INVENTORY_ONLY: 'INVENTORY_ONLY',
  INVENTORY_PLUS_SOLD: 'INVENTORY_PLUS_SOLD',
  IN_RECON: 'IN_RECON',
  MANUALLY_CREATED: 'MANUALLY_CREATED',
};

const convertUiFilterToApiFilter = (
  value: string | string[],
  apiFilterId: string,
  upMap?: Record<string, string>
) => {
  if (value) {
    if (upMap && typeof value === 'string') value = upMap[value];
    if (Array.isArray(value)) {
      const filterStrings = value.map((item) => `${apiFilterId}::${item}`);
      return filterStrings.join(',');
    }
    if (value) {
      return `${apiFilterId}::${value}`;
    }
    return '';
  }
};

export const getCurrentFilterParamsFromUrl = (): string => {
  const filters: string[] = [];
  FILTER_PARAM_NAMES.forEach((name) => {
    const value = filterGetters[name]();
    if (!value) {
      return;
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return;
      }
    }
    filters.push(value);
  });
  return filters.join(',');
};

export const useActiveFilters = (): { filter: string; value: any }[] => {
  const location = useLocation();
  return useMemo(() => {
    const filters: any = {};
    if (!location.search) {
      return [];
    }

    const currentFilters = getCurrentFilterParamsFromUrl();
    if (currentFilters.trim().length > 0) {
      const values = currentFilters.split(',');
      values.forEach((item) => {
        let [filter, value] = item.split('::');
        if (!filters[filter]) {
          filters[filter] = [value];
        } else {
          filters[filter].push(value);
        }
      });
    }

    return Object.keys(filters).map((filter) => {
      if (filters[filter].length > 1) {
        return { filter, value: filters[filter] };
      } else {
        return { filter, value: filters[filter][0] };
      }
    });
  }, [location.search]);
};

export const INVENTORY_PAGE_SIZE = 20;

export function getVehicleListParams() {
  const path = `/inventory`;
  const filterIds = getCurrentFilterParamsFromUrl();
  const searchString = getLastQueryParamWithName('s') || '';
  const sortId = getLastQueryParamWithName('sort') || 'AGE';
  const sortDirection =
    getLastQueryParamWithName('sortDirection') || 'DESCENDING';
  const queryKey = [path, filterIds, searchString, sortId, sortDirection];
  return {
    path,
    filterIds,
    searchString,
    sortId,
    sortDirection,
    queryKey,
  };
}

/**
 * @package api/inventory
 * @description - GET inventory list filtered by query object properties and paged by index.
 */
export function useVehicleList(startIndex: number = 0) {
  const { path, filterIds, searchString, sortId, sortDirection, queryKey } =
    getVehicleListParams();

  return useInfiniteQuery<APIResponse<VehicleSummary[]>>(
    queryKey,
    async ({ pageParam = { startIndex } }) => {
      const searchParams = new URLSearchParams();
      if (filterIds) {
        searchParams.set('filterIds', filterIds);
      }
      if (searchString) {
        searchParams.set('s', searchString);
      }
      searchParams.set('sortId', sortId);
      searchParams.set('sortDirection', sortDirection);
      searchParams.set('pageSize', `${INVENTORY_PAGE_SIZE}`);
      searchParams.set('startIndex', `${pageParam.startIndex}`);
      searchParams.set('includeTaskNotes', 'true');
      const url = `${path}?${searchParams.toString()}`;

      return defaultMetaQueryFn(url);
    },
    {
      refetchOnMount: false,
      staleTime: 1800000,
    }
  );
}

export function useCreateVehicle(isRecon: boolean) {
  const path = isRecon ? '/recon' : '/inventory';

  return useMutation(
    (vehicle: CreateVehiclePayload) => {
      return defaultMutationFn(path, 'POST', vehicle);
    },
    {
      // Adding onError so that a type could be declared for the error.
      // Typescript wouldn't allow callers to attempt accessing e.message without this.
      onError: async (e: Error) => {
        return e;
      },
    }
  );
}

export function useUpdateVehicle(vehicleId?: string) {
  const path = `/inventory/${vehicleId}`;
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateVehiclePayload) => defaultMutationFn(path, 'PUT', data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/inventory/${vehicleId}`]);
      },
      onError: async (e: Error) => {
        return e;
      },
    }
  );
}

export function useUpdateReconInfo(vehicleId?: string) {
  const path = `/inventory/${vehicleId}/reconInfo`;
  const queryClient = useQueryClient();

  return useMutation((data: any) => defaultMutationFn(path, 'PUT', data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([`/inventory/${vehicleId}`]);
    },
  });
}

export function useVehicleAttachments(id: string) {
  const path = `/inventory/${id}/attachments`;
  return useQuery<ApiResponse<VehicleAttachment[]>>([path]);
}

export function useVehicleMedia(id: string) {
  const path = `/inventory/${id}/media`;
  return useQuery<
    ApiResponse<{ images: VehicleImage[]; videos: VehicleImage[] }>
  >([path]);
}

export function useUpdateVehicleInfo(vehicleId?: string) {
  const path = `/recon/${vehicleId}/vehicleInfo`;
  const queryClient = useQueryClient();

  return useMutation(
    (vehicleData: Partial<VehicleCard>) =>
      defaultMutationFn(path, 'PUT', vehicleData),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/inventory/${vehicleId}`]);
      },
    }
  );
}

export function useVehicleRepairOrders(id: string) {
  const path = `/inventory/${id}/repairOrders`;
  return useQuery<ApiResponse<VehicleRepairOrder[]>>([path]);
}

export function useVehicleTags(
  vehicleId?: string,
  options?: { enabled?: boolean }
) {
  const path = `/inventory/${vehicleId}/tags`;
  const enabled = options?.enabled ?? true;
  return useQuery<ApiResponse<VehicleTag[]>>([path], {
    enabled: !!vehicleId && enabled,
  });
}

export function useAssignTags(vehicleId?: string) {
  const path = `/inventory/${vehicleId}/tags`;
  const queryClient = useQueryClient();

  return useMutation(
    (tags: VehicleTag[]) => {
      const tagIds = tags.map((tag) => tag.tagId);
      return defaultMutationFn(path, `PUT`, tagIds);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/inventory/${vehicleId}/tags`]);
      },
    }
  );
}

export function useUnassignTags(vehicleId?: string) {
  const path = `/inventory/${vehicleId}/tags`;
  const queryClient = useQueryClient();

  return useMutation(
    (tags: VehicleTag[]) => {
      const tagInstanceIds = tags.map((tag) => tag.instanceId);
      return defaultMutationFn(path, 'DELETE', tagInstanceIds);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/inventory/${vehicleId}/tags`]);
      },
    }
  );
}

export function useCreateAndAssignVehicleTag(vehicleId?: string) {
  const path = `/inventory/${vehicleId}/tags`;

  return useMutation((tag: VehicleTag) => defaultMutationFn(path, 'POST', tag));
}

export function usePrintVehicleInfoPDF() {
  return useMutation(({ vehicleId }: { vehicleId: string }) => {
    const displayAllSections =
      '?excludeNotes=false&excludeTasks=false&excludeHeader=false&excludeAppraisalInfo=false';
    const path = `/inventory/${vehicleId}/export/pdf${displayAllSections}`;
    return defaultMetaQueryFn(path);
  });
}

export function useBackToReconWorkflow() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ vehicleId }: { vehicleId: string }) => {
      const path = `/inventory/${vehicleId}/moveBackToRecon`;
      return defaultMutationFn(path, 'PUT');
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([`/inventory/${variables.vehicleId}`]);
        queryClient.invalidateQueries([`/assignments`]);
        // TODO invalidate inventory
      },
    }
  );
}

import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { defaultMutationFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

type CreateInvoiceVariables = {
  inventoryId: string;
  taskIds: string[];
};

const useCreateInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: CreateInvoiceVariables) => {
      return defaultMutationFn('/vendor-invoice', 'POST', data) as Promise<
        UseMutationResult<Invoice>
      >;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['/vendor-inventory']);
      },
    }
  );
};

export default useCreateInvoiceMutation;

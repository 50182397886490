import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';

import { getParsedMessage } from 'components/pages/Notes/utils';
import VehicleImageView from 'components/shared/VehicleImageView';
import { NotesByDate } from 'models';
import { getCalendarDateStringFromDate } from 'utils/time';

import VehicleCardAttachmentEditDropDown from './VehicleCardAttachmentEditDropDown';
import VehicleCardNoteEditDropDown from './VehicleCardNoteEditDropDown';
import VehicleCardNoteInput from './VehicleCardNoteInput';

import './VehicleCardNotes.scss';

interface VehicleCardNotesProps {
  vehicleId: string;
  notes: NotesByDate[];
  isInTab?: boolean;
}
const VehicleCardNotes = ({
  vehicleId,
  notes,
  isInTab = false,
}: VehicleCardNotesProps) => {
  const notesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    notesRef?.current?.scrollTo({
      top: notesRef?.current?.scrollHeight,
      behavior: 'auto',
    });
  }, [notes]);

  const Notes = useMemo(() => {
    if (!notes?.length) {
      return (
        <div className="VehicleCardNotes-emptyNotes">
          When a note is shared about this vehicle, it will appear here.
        </div>
      );
    }
    return notes?.map((notesByDate) => {
      const { notesByUser, timestamp } = notesByDate;
      const dateConversion = getCalendarDateStringFromDate(new Date(timestamp));
      return (
        <div
          key={`notesByDate-${timestamp}`}
          className="VehicleCardNotes-notesByDate"
        >
          <div className="VehicleCardNotes-date">{dateConversion}</div>
          {notesByUser.map((noteByUser) => {
            const { fullName, userId, notes } = noteByUser;
            const time = moment(notes?.[0]?.time).format('h:mm A');
            return (
              <div
                key={`note-${fullName}-${time}`}
                className="VehicleCardNotes-note"
              >
                <div className="VehicleCardNotes-senderTime">
                  <div className="VehicleCardNotes-sender">{fullName}</div>
                  <div className="VehicleCardNotes-time">{time}</div>
                </div>
                {notes?.map((note, index) => {
                  const { attachments, id: noteId } = note;
                  const text = getParsedMessage(note.note);
                  return (
                    <div
                      key={noteId}
                      className="VehicleCardNotes-messageContainer"
                    >
                      <div className="VehicleCardNotes-messageDropdown">
                        <div
                          key={`message-${note.time}-${index}`}
                          className="VehicleCardNotes-message"
                        >
                          {text}
                        </div>
                        <VehicleCardNoteEditDropDown
                          note={note}
                          userId={userId}
                          vehicleId={vehicleId}
                        />
                      </div>
                      {attachments?.map((attachment) => {
                        const { uri, id: attachmentId } = attachment;
                        return (
                          <div
                            key={attachmentId}
                            className="VehicleCardNotes-attachment"
                          >
                            <a href={uri} target={'_blank'} rel="noreferrer">
                              <VehicleImageView
                                size="sm"
                                imageUrl={attachment.uri}
                              />
                            </a>
                            <VehicleCardAttachmentEditDropDown
                              attachmentId={attachmentId}
                              noteId={noteId!}
                              userId={userId}
                              vehicleId={vehicleId}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    });
  }, [notes, vehicleId]);

  const tabClass = isInTab ? 'VehicleCardNotesTab' : '';

  return (
    <div className={`VehicleCardNotes ${tabClass}`}>
      <div ref={notesRef} className="VehicleCardNotes-notes">
        {Notes}
      </div>
      <VehicleCardNoteInput vehicleId={vehicleId} />
    </div>
  );
};

export default VehicleCardNotes;

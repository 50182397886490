/* eslint-disable unused-imports/no-unused-imports */
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useNavigate, WindowLocation } from '@reach/router';
import { FC, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import {
  useBackToReconWorkflow,
  useCompleteWorkflow,
  useDeleteVehicleWorkflow,
  useDeleteWorkflow,
  usePrintVehicleInfoPDF,
} from 'api';
import { RECON_STEP_STATE } from 'common/constants';
import permissions from 'common/permissions';
import strings from 'common/strings';
import AddVehicleModal from 'components/pages/VehicleCollectionBrowser/VehicleListHeader/AddVehicleModal';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import CustomDropdownToggle from 'components/shared/CustomDropdownToggle';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import StepButtons from 'components/shared/StepButtons';
import TagList from 'components/shared/TagList';
import VehicleImageView from 'components/shared/VehicleImageView';
import {
  useCurrentLocationId,
  usePermissions,
  useVendor,
  useWindowSize,
} from 'hooks';
import { VehicleSummary } from 'models';
import { DASHBOARD_RELATIVE } from 'navigation/routes';
import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';

import VehicleInfoItem from '../VehicleInfoItem';
import VehicleTitle from '../VehicleTitle';

import './VehicleHeader.scss';

interface VehicleHeaderProps {
  vehicle: VehicleSummary;
  location?: WindowLocation;
  path?: string;
  className?: string;
  imageUrl?: string;
}

const confirmationModalProps = {
  onClose: () => {},
  onAccept: () => {},
  title: '',
  body: '',
  mainButtonTitle: '',
};

const VehicleHeader: FC<VehicleHeaderProps> = ({ vehicle, className }) => {
  const { isVendor } = useVendor();
  const navigate = useNavigate();
  const locationId = useCurrentLocationId();
  const printVehicleInfoAsync = usePrintVehicleInfoPDF();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const windowSize = useWindowSize();
  const backToReconWorkflowAsync = useBackToReconWorkflow();
  const completeWorkflowMutation = useCompleteWorkflow(vehicle.vehicleCard.id);
  const deleteWorkflowMutation = useDeleteWorkflow(vehicle.vehicleCard.id);
  const deleteVehicleMutation = useDeleteVehicleWorkflow(
    vehicle.vehicleCard.id
  );
  const { mileage, reconStatus, vehicleImage: imageUrl } = vehicle.vehicleCard;

  interface MetaData {
    source: string;
  }

  const [isFetchingDownload, setIsFetchingDownload] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isEditVehicleModalOpen, setIsEditVehicleModalOpen] = useState(false);
  const interceptDropdownToggle = (
    isOpen: boolean,
    event: unknown,
    metadata: MetaData
  ) => {
    if (metadata.source === 'select') {
      setDropdownOpen(true);
      return;
    }
    setDropdownOpen(isOpen);
  };
  const { hasPermission } = usePermissions();

  const showForceCompletion = useMemo(() => {
    return (
      hasPermission(permissions.RECON_VDP_FORCE_COMPLETE_UPDATE) &&
      (reconStatus === RECON_STEP_STATE.ACTIVE ||
        vehicle?.stepItem?.terminalStep)
    );
  }, [hasPermission, reconStatus, vehicle?.stepItem?.terminalStep]);

  const goBack = () => {
    const from = getLastQueryParamWithName('fromVDP', 'recon');
    switch (from) {
      case 'recon':
        navigateToSRP('recon');
        break;
      case 'inventory':
        navigateToSRP('inventory');
        break;
      case 'dashboard':
        navigate(`/${locationId}/${DASHBOARD_RELATIVE}`);
        break;
      default:
        navigateToSRP('recon');
    }
  };

  const navigateToSRP = (vehicleType: string) => {
    if (!vehicleType) vehicleType = 'recon';
    let path = `/${locationId}/${vehicleType}`;
    if (vehicle) {
      path += `?preview=${vehicle.vehicleCard.id}%2C${vehicle.vehicleCard.stockNumber}`;
    }
    navigate(path);
  };

  const onDelete = () => {
    setDropdownOpen(false);
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_REMOVE;
    confirmationModalProps.onAccept = removeFromRecon;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_REMOVE;
    confirmationModalProps.body = strings.REMOVE_FROM_RECON_DESCRIPTION;

    handleOpenConfirmationDialog();
  };

  const onComplete = () => {
    setDropdownOpen(false);
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_COMPLETE;
    confirmationModalProps.onAccept = forceCompleteWorkflow;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_COMPLETE;
    confirmationModalProps.body = strings.FORCE_COMPLETION_DESCRIPTION;

    handleOpenConfirmationDialog();
  };

  const onDeleteVehicle = () => {
    setDropdownOpen(false);
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_DELETE;
    confirmationModalProps.onAccept = deleteVehicle;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_DELETE;
    confirmationModalProps.body = strings.DELETE_VEHICLE_DESCRIPTION;

    handleOpenConfirmationDialog();
  };

  const handleOpenConfirmationDialog = () => {
    setDropdownOpen(false);
    setIsConfirmationDialogOpen(true);
  };

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const forceCompleteWorkflow = async () => {
    try {
      handleCloseConfirmationDialog();
      await completeWorkflowMutation.mutateAsync();
    } catch (error) {}
  };

  const removeFromRecon = async () => {
    try {
      handleCloseConfirmationDialog();
      await deleteWorkflowMutation.mutateAsync();
      navigate('/');
    } catch (error) {}
  };

  const deleteVehicle = async () => {
    try {
      handleCloseConfirmationDialog();
      await deleteVehicleMutation.mutateAsync();
      navigate('/');
    } catch (error) {}
  };

  const printVehicleInfoPDF = async (): Promise<void> => {
    try {
      setIsFetchingDownload(true);
      const response = await printVehicleInfoAsync.mutateAsync({
        vehicleId: vehicle?.vehicleCard?.id ?? '',
      });
      if (!response) return;
      window.open(response.uri, '_newtab');
      setIsFetchingDownload(false);
    } catch {
      setDropdownOpen(false);
    }
    setIsFetchingDownload(false);
    setDropdownOpen(false);
  };

  const onBackToRecon = () => {
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_BACK_TO_RECON;
    confirmationModalProps.onAccept = backToReconWorkflow;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_SEND_TO_RECON;
    confirmationModalProps.body = strings.DESCRIPTION_BACK_TO_RECON;

    handleOpenConfirmationDialog();
  };

  const onEditVehicle = () => {
    setIsEditVehicleModalOpen(true);
  };

  function closeEditVehicleModal() {
    setIsEditVehicleModalOpen(false);
  }
  const backToReconWorkflow = async () => {
    try {
      handleCloseConfirmationDialog();
      await backToReconWorkflowAsync.mutateAsync({
        vehicleId: vehicle?.vehicleCard?.id ?? '',
      });
    } catch (error) {}
  };

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const reconActions = () => (
    <Dropdown.Menu className="VehicleInfoItems-recon-dropdown">
      {showForceCompletion && (
        <Dropdown.Item onClick={onComplete}>
          {strings.FORCE_COMPLETION}
        </Dropdown.Item>
      )}
      {hasPermission(permissions.RECON_VDP_REMOVE_FROM_RECON_UPDATE) &&
        reconStatus === RECON_STEP_STATE.ACTIVE && (
          <Dropdown.Item onClick={onDelete}>
            {strings.REMOVE_FROM_RECON}
          </Dropdown.Item>
        )}
      {hasPermission(permissions.RECON_VDP_FORCE_COMPLETE_UPDATE) &&
        (reconStatus === RECON_STEP_STATE.COMPLETED ||
          reconStatus === RECON_STEP_STATE.MANUALLY_REMOVED ||
          reconStatus === RECON_STEP_STATE.NOT_STARTED) && (
          <Dropdown.Item onClick={onBackToRecon}>
            {strings.DROPDOWN_TITLE_SEND_TO_RECON}
          </Dropdown.Item>
        )}
      {hasPermission(permissions.RECON_VDP_CREATE) &&
        vehicle?.vehicleCard?.inventoryStatus ===
          strings.INVENTORY_STATUS_MANUALLY_CREATED &&
        vehicle?.vehicleCard?.updatedBy ===
          strings.UPDATED_BY_RECON_VELOCITY && (
          <Dropdown.Item
            onClick={onDeleteVehicle}
            className="loadingIconAlongside"
          >
            {strings.DROPDOWN_TITLE_DELETE}
          </Dropdown.Item>
        )}
      {hasPermission(permissions.RECON_VDP_UPDATE) &&
        vehicle?.vehicleCard?.inventoryStatus ===
          strings.INVENTORY_STATUS_MANUALLY_CREATED &&
        vehicle?.vehicleCard?.updatedBy ===
          strings.UPDATED_BY_RECON_VELOCITY && (
          <Dropdown.Item onClick={onEditVehicle}>
            {strings.DROPDOWN_TITLE_EDIT_VEHICLE}
          </Dropdown.Item>
        )}
      <Dropdown.Item
        onClick={printVehicleInfoPDF}
        className="loadingIconAlongside"
      >
        {strings.PRINT_VDP_INFO}
        {isFetchingDownload && <LoadingIndicator size={18} />}
      </Dropdown.Item>
    </Dropdown.Menu>
  );
  return (
    <div className="VehicleHeader full-width">
      {isVendor && (
        <div className="VehicleHeader-back-container">
          <IconButton color="secondary" onClick={handleBack}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      <div className={`VehicleHeader-image-container ${className}`}>
        <VehicleImageView size="sm" imageUrl={imageUrl} />
      </div>

      <div className="VehicleHeader-col VehicleHeader-title">
        <VehicleTitle
          vehicle={vehicle}
          miles={mileage}
          isDesktop={windowSize.isDesktopViewport()}
          navigateBack={goBack}
          className={className}
        />
      </div>
      <div className="VehicleInfoItems-dropdown">
        <Dropdown
          show={dropdownOpen}
          onToggle={interceptDropdownToggle}
          className="d-none d-sm-block"
          drop="down"
        >
          <Dropdown.Toggle as={CustomDropdownToggle} id="recon-dropdown-id">
            <IconButton size="small">
              <MoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          {reconActions()}
        </Dropdown>
        {isConfirmationDialogOpen && (
          <ConfirmationDialog {...confirmationModalProps} />
        )}
      </div>
      <div className="VehicleInfoItems full-width flex-rows">
        {windowSize.isDesktopViewport() && (
          <VehicleInfoItem inventoryId={vehicle.vehicleCard.id!} />
        )}
        {!isVendor && (
          <div className="vdp-tags-list">
            <TagList
              tags={vehicle.tags ?? []}
              vehicle={vehicle}
              showEditButton
              truncate
            />
          </div>
        )}
      </div>
      <div className="VehicleHeader-step-button">
        {vehicle.stepItem && (
          <StepButtons
            vehicleId={vehicle.vehicleCard.id}
            currentStep={vehicle.stepItem}
            small
          />
        )}
      </div>
      {isEditVehicleModalOpen && (
        <div className="VehicleList-add-vehicle-modal-container">
          <AddVehicleModal onClose={closeEditVehicleModal} vehicle={vehicle} />
        </div>
      )}
    </div>
  );
};

export default VehicleHeader;

import { navigate } from '@reach/router';
import { useContext } from 'react';

import strings from 'common/strings';
import TextCopy from 'components/shared/Inputs/TextCopy';
import Skeleton from 'components/shared/Skeleton';
import { useWindowSize } from 'hooks/useWindowSize';
import { overviewBuilder } from 'navigation/routes';

import { VehicleCardContext } from '../../VehicleCard';

import './VehicleCardYearMakeModel.scss';

interface VehicleCardYearMakeModelProps {
  size?: 'wide' | 'small';
}

var VehicleCardYearMakeModel = ({
  size = 'small',
}: VehicleCardYearMakeModelProps) => {
  const { vehicle } = useContext(VehicleCardContext);
  const isMobile = useWindowSize().isMobileViewport();
  const { year, make, model, vin, id } = vehicle?.vehicleCard ?? {};
  if (size === 'wide') {
    return (
      <Skeleton renderSkeleton={!vehicle}>
        <div className="Vehicle-card-year-make-model">
          {vehicle && (year || make)
            ? `${year || ''} ${make || ''} ${model || ''}`
            : strings.EMPTY_VALUE}
        </div>
      </Skeleton>
    );
  }

  const handleClickNavToRecon = (): Promise<void> | null => {
    if (id && isMobile) {
      return navigate(`${overviewBuilder(id, true)}`);
    }
    return null;
  };

  return (
    <div
      className="VehicleCard-year-make-model"
      onClick={() => {
        handleClickNavToRecon();
      }}
    >
      <div className="Vehicle-card-year-make">
        <Skeleton renderSkeleton={!vehicle}>
          {vehicle && (year || make)
            ? `${year || ''} ${make || ''}`
            : strings.EMPTY_VALUE}
        </Skeleton>
      </div>
      <div className="VehicleCard-model">
        <Skeleton renderSkeleton={!vehicle}>{model || ''}</Skeleton>
      </div>
      <Skeleton renderSkeleton={!vehicle}>
        <div className="VehicleCard-vin">
          <TextCopy value={vin!} />
        </div>
      </Skeleton>
    </div>
  );
};

export default VehicleCardYearMakeModel;

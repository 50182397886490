import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';

import { getAPIHeaders } from 'api/lib';
import {
  clearCredentials,
  saveCredentials,
} from 'api/lib/credentialsPersistence';
import { strings } from 'common';
import { Authentication } from 'models';
import useUploadStore from 'store/uploads/useUploadStore';

import { getTokenWithExistingServiceWorker } from '../../firebase';
import { updateDeviceToken } from './useUpdateDeviceTokenMutation';

export async function handleUpdateDeviceToken() {
  try {
    const deviceId = await getTokenWithExistingServiceWorker();
    if (deviceId) {
      updateDeviceToken(deviceId);
    }
  } catch (error) {
    console.log('Error getting deviceId from Firebase', error);
  }
}

export function useLoginMutation() {
  const client = useQueryClient();
  const uploadStoreData = useUploadStore((uploadStore) => ({
    initialize: uploadStore.initialize,
    closeUppyInstances: uploadStore.closeUppyInstances,
  }));
  return useMutation(
    async ({
      emailAddress,
      password,
    }: {
      emailAddress: string;
      password: string;
    }) => {
      clearCredentials();
      const headers = await getAPIHeaders('POST');
      const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
        headers?.options,
        {
          body: JSON.stringify({
            password,
            username: emailAddress,
            authenticationStrategy: 'V2',
          }),
        }
      );

      const response = await fetch(
        `${headers?.host}/authenticate?source=${strings.LOGIN_SOURCE}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const user: Authentication = await response.json();
      if (user) {
        saveCredentials({
          authBearerToken: user.token,
          authBearerTokenExpiration: user.expirationDate,
          authRefreshToken: user.refreshToken,
        });
        client.setQueryData(['/authenticate/token'], user, {
          updatedAt: Date.now(),
        });
      }
      uploadStoreData.closeUppyInstances();
      uploadStoreData.initialize();
      handleUpdateDeviceToken();
    }
  );
}

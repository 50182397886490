import { AddAPhoto } from '@material-ui/icons';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import TruncatedList from 'react-truncate-list';

import { useDeleteTasksAttachment, useTasksAttachments } from 'api';
import { isImage } from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Alert from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import ModalCarousel from 'components/shared/ModalCarousel';
import PermissionsGate from 'components/shared/PermissionsGate';
import {
  Attachment,
  convertTaskAttachmentsToVehicleImage,
  Task,
  VehicleSummary,
} from 'models';
import {
  ALLOWED_FILETYPES_STRING,
  UploadItem,
  UploadStatus,
  UploadTarget,
  UploadType,
} from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';

import './TaskPhotos.scss';
import 'react-truncate-list/dist/styles.css';

interface TaskPhotosProps {
  task?: Task;
  disabled?: boolean;
  vehicle: VehicleSummary;
}

const TaskPhotos: React.FC<TaskPhotosProps> = ({
  task,
  disabled = false,
  vehicle,
}) => {
  const { data, isLoading } = useTasksAttachments(
    vehicle?.vehicleCard?.id ?? '',
    task?.id ?? ''
  );
  const { data: attachments = [] } = data ?? {};
  const attachmentRemoveMutation = useDeleteTasksAttachment(
    vehicle?.vehicleCard?.id ?? '',
    task?.id ?? ''
  );

  useEffect(() => {
    if (attachments.length === 0) {
      setIsGalleryOpen(false);
    }
  }, [attachments.length]);

  const queryClient = useQueryClient();
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [tempAttachmentLoading, setTempAttachmentLoading] = useState(false);
  const [errorUploadingAttachment, setErrorUploadingAttachment] =
    useState(false);
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
    uploadFiles: uploadStore.uploadFiles,
  }));

  const openGallery = (photoIndex: number = 0) => {
    setGalleryIndex(photoIndex);
    setIsGalleryOpen(true);
  };
  const closeGallery = () => setIsGalleryOpen(false);

  const refreshAttachments = () => {
    const path = `/inventory/${vehicle?.vehicleCard?.id ?? ''}/tasks/${
      task?.id ?? ''
    }/attachments`;
    queryClient.invalidateQueries([path]);
  };

  const hasTusUploadInProgress =
    uploadStoreData.uploads.filter(
      (upload: UploadItem) =>
        upload.meta.target.id === task?.id &&
        upload.uploadStatus === UploadStatus.UPLOADING
    ).length > 0;

  const handleCloseMaxFilesError = () => setMaxFilesError(false);
  const handleCloseApiError = () => setErrorUploadingAttachment(false);

  const handleTusUpload = async (files: File[]) => {
    setTempAttachmentLoading(true);
    try {
      const onUploadSuccess = async () => {
        refreshAttachments();
        setTempAttachmentLoading(false);
      };
      const onUploadCancel = () => setTempAttachmentLoading(false);
      const onUploadError = () => setTempAttachmentLoading(false);
      const uploadTarget: UploadTarget = {
        type: UploadType.TASK,
        id: task?.id,
        targetObj: task,
      };
      uploadStoreData.uploadFiles(
        files,
        vehicle?.vehicleCard?.id!,
        uploadTarget,
        onUploadSuccess,
        onUploadError,
        onUploadCancel
      );
    } catch (error) {
      console.error('uppy error: ', error);
    }
  };

  const handleChange = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];

    if (files.length > 5) {
      setMaxFilesError(true);
      return;
    }
    handleTusUpload(files);
  };

  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', ALLOWED_FILETYPES_STRING);
  fileSelector.setAttribute('multiple', 'multiple');
  fileSelector.onchange = handleChange;

  return (
    <div className="TaskPhotos">
      <TruncatedList
        className="TaskPhotos-photos"
        renderTruncator={({ hiddenItemsCount }) => {
          const lastIndex = attachments?.length - 1;
          const lastAttachment = attachments[lastIndex];
          const url =
            lastAttachment && lastAttachment.uri ? lastAttachment.uri : '';
          return (
            !isLoading && (
              <div className="TaskPhotos-photos">
                <div
                  className="TaskPhotos-photo-container TaskPhotos-photo-container-truncated"
                  role="none"
                  onClick={() => openGallery(0)}
                >
                  {isImage(url) ? (
                    <img className="TaskPhotos-photo" src={url} alt="task" />
                  ) : (
                    <video className="TaskPhotos-photo" src={url} />
                  )}
                  <div className="TaskPhotos-photo-count-overlay">{`+${hiddenItemsCount}`}</div>
                </div>
              </div>
            )
          );
        }}
      >
        {!isLoading &&
          attachments.map((photo: Attachment, index: number) => (
            <div
              key={photo.id}
              className="TaskPhotos-photo-container"
              role="none"
              onClick={() => openGallery(index)}
            >
              {isImage(photo.uri) ? (
                <img className="TaskPhotos-photo" src={photo.uri} alt="task" />
              ) : (
                <video className="TaskPhotos-photo" src={photo.uri} />
              )}
            </div>
          ))}
        {(tempAttachmentLoading || hasTusUploadInProgress || isLoading) && (
          <div className="TaskPhotos-photo-container TaskPhotos-loading-photo">
            <LoadingIndicator />
          </div>
        )}
      </TruncatedList>
      <PermissionsGate
        permissions={[permissions.INVENTORY_VDP_TASKS_ATTACHMENT_CREATE]}
      >
        <div
          className={`TaskPhotos-photo-container TaskPhotos-add-photo ${
            disabled ? 'TaskPhotos-add-photo-disabled' : ''
          }`}
          role="none"
          onClick={() => (disabled ? false : fileSelector.click())}
        >
          <AddAPhoto />
        </div>
      </PermissionsGate>
      <ModalCarousel
        modalIsOpen={isGalleryOpen}
        currentIndex={galleryIndex}
        images={convertTaskAttachmentsToVehicleImage(attachments.slice())}
        onClose={closeGallery}
        deletePermission={
          task?.status === 'COMPLETE'
            ? 'NONE'
            : permissions.INVENTORY_VDP_TASKS_ATTACHMENT_DELETE
        }
        onDeleteImage={(id: string) => {
          setGalleryIndex(0);
          attachmentRemoveMutation.mutate(id);
        }}
        assignPermission="NONE"
      />
      {maxFilesError && (
        <Alert
          open={maxFilesError}
          duration={3000}
          handleClose={handleCloseMaxFilesError}
          contentProps={{
            message: strings.TOO_MANY_FILES,
            variant: 'error',
            onClose: handleCloseMaxFilesError,
          }}
        />
      )}
      {errorUploadingAttachment && (
        <Alert
          open={errorUploadingAttachment}
          duration={3000}
          handleClose={handleCloseApiError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: handleCloseApiError,
          }}
        />
      )}
    </div>
  );
};
export default TaskPhotos;

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button as MUIButton,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { RouteComponentProps, useParams } from '@reach/router';
import { useCallback, useState } from 'react';

import useGetInvoiceQuery from 'api/invoicing/useGetInvoiceQuery';
import usePayInvoiceMutation from 'api/invoicing/usePayInvoiceMutation';
import { strings } from 'common';
import ApiError from 'components/shared/ApiError';
import Button from 'components/shared/Button';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import { Invoice } from '../Invoice';

import './PayInvoice.scss';

type Props = RouteComponentProps;

const PayInvoice = ({ navigate }: Props) => {
  const { invoiceId } = useParams();
  const getInvoiceQuery = useGetInvoiceQuery(invoiceId);
  const payInvoiceMutation = usePayInvoiceMutation();

  const [showDialog, setShowDialog] = useState(false);

  const invoice = getInvoiceQuery?.data?.data;

  const isLoading = getInvoiceQuery.isLoading || payInvoiceMutation.isLoading;
  const isSuccess = payInvoiceMutation.isSuccess;
  const error = payInvoiceMutation.error;

  const handleBack = useCallback(() => {
    navigate?.('../../');
  }, [navigate]);

  const handlePayInvoice = useCallback(async () => {
    if (invoice?.id) {
      try {
        await payInvoiceMutation.mutateAsync(
          {
            invoiceId: invoice.id,
          },
          {
            onSuccess: () => {
              getInvoiceQuery.refetch();
            },
          }
        );
        setShowDialog(true);
      } catch (error) {}
    }
  }, [getInvoiceQuery, invoice?.id, payInvoiceMutation]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  let content = null;
  if (getInvoiceQuery.isLoading) {
    content = <LoadingIndicator />;
  } else if (invoice && getInvoiceQuery.isSuccess) {
    content = <Invoice invoice={invoice} />;
  }

  return (
    <div className="vendor-pay-invoice">
      <header className="vendor-pay-invoice-header">
        <MUIButton
          variant="text"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Back
        </MUIButton>
      </header>
      <main className="vendor-pay-invoice-content">{content}</main>
      <footer className="vendor-pay-invoice-footer">
        <Button
          variant="primary"
          type="button"
          onClick={handlePayInvoice}
          disabled={isLoading || isSuccess}
        >
          {strings.PAY_INVOICE}
        </Button>
      </footer>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '350px',
          }}
        >
          <CheckCircleOutlineIcon
            style={{ color: green[500], fontSize: 150 }}
          />
          <Typography variant="subtitle1">Invoice Paid!</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" type="button" onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {error && <ApiError error={error} />}
    </div>
  );
};

export default PayInvoice;

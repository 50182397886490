import { UseQueryOptions } from '@tanstack/react-query';

import { CacheStrategyName, queryCacheStrategies } from 'api';

import { getCredentialValue, saveCredentials } from './credentialsPersistence';

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

const host = `${process.env.REACT_APP_RECONVELOCITY_APPSERVICE_URL_OVERRIDE}`;

const ONE_MINUTE = 1000 * 60;

export async function getBearerToken() {
  let authBearerToken = getCredentialValue('authBearerToken');

  const tokenExpirationTimestamp = getCredentialValue(
    'authBearerTokenExpiration'
  );

  const tokenExpirationDateTime = tokenExpirationTimestamp
    ? new Date(tokenExpirationTimestamp).getTime()
    : null;

  const now = new Date().getTime();
  if (tokenExpirationDateTime && now >= tokenExpirationDateTime - ONE_MINUTE) {
    const authResponse = await getNewTokenData();
    authBearerToken = authResponse?.token;
  }
  return authBearerToken;
}

const defaultHeaders = {
  'content-type': 'application/json',
  'x-api-authorization': 'allowed',
  'x-client-app-version': process.env.REACT_APP_VERSION ?? 'undefined',
  'x-client-type': 'WEB',
};

/**
 * @name getNewTokenData
 * @description Super-slim helper to grab current session tokens
 *
 * @returns token object from API
 */
export async function getNewTokenData() {
  const path = '/authenticate';

  const authRefreshToken = getCredentialValue('authRefreshToken');
  if (!authRefreshToken) {
    return;
  }

  const options = {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${authRefreshToken}`,
    },
  };

  const response = await fetch(host + path, options);
  if (!response.ok) {
    return;
  }

  const authResponse = await response.json();
  saveCredentials({
    authBearerToken: authResponse.token,
    authBearerTokenExpiration: authResponse.expirationDate,
    authRefreshToken: authResponse.refreshToken,
  });

  return authResponse;
}

export function getAuthTenantId() {
  const urlSegments = window.location.pathname.split('/');
  let tenantId = urlSegments?.[1] ?? '';
  if (urlSegments?.[1] === 'velocity-insight') {
    tenantId = urlSegments?.[3];
  }
  return tenantId;
}

/**
 * @name getAPIHeaders
 * @description  function to generate boilerplate for API calls.
 * */
export async function getAPIHeaders(
  method: HttpMethod = 'GET',
  headers = {}
): Promise<{ host: string; options: any }> {
  const authBearerToken = await getBearerToken();

  const options = {
    method,
    headers: {
      ...defaultHeaders,
      ...headers,
      'auth-tenant-id': `${getAuthTenantId()}`,
    } as Record<string, string>,
  };
  if (authBearerToken) {
    options.headers.Authorization = `Bearer ${authBearerToken}`;
  }
  return { host, options };
}

export function makeQueryString(obj: { [key: string]: any }) {
  return Object.getOwnPropertyNames(obj)
    .map((key) => {
      const val = obj[key];
      return val !== null ? `${key}::${obj[key]}` : null;
    })
    .join(',');
}

export function makeOptionsFromSettings<T>(
  settings?: UseQueryOptions<T, Error> | CacheStrategyName
) {
  if (!settings) {
    return queryCacheStrategies.basic;
  }

  return typeof settings === 'string'
    ? queryCacheStrategies[settings as CacheStrategyName]
    : settings;
}

import { RouteComponentProps } from '@reach/router';

import { useCreateInvoiceMutation } from 'api/invoicing';

import Invoice from '../Invoice/Invoice';

type Props = RouteComponentProps;

const NewInvoice = (props: Props) => {
  const { isLoading, isError, error } = useCreateInvoiceMutation();

  let content = null;
  if (isLoading) {
    content = 'is loading';
  } else if (isError) {
    content = error;
  } else {
    content = <Invoice />;
  }

  return <div className="new-invoice">{content}</div>;
};

export default NewInvoice;

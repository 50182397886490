import { navigate } from '@reach/router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getCredentialValue,
  saveCredentials,
} from 'api/lib/credentialsPersistence';
import { defaultMutationFn } from 'api/useAPI';
import { Authentication, Location } from 'models';
import { getExtensionModeInfo } from 'utils/extension';

import { useSwitchRooftopMutation } from './useSwitchRooftopMutation';

const TWELVE_HOURS = 12 * 60 * 60 * 1000;

export function useToken() {
  const path = '/authenticate/token';
  const enabled =
    !!getCredentialValue('authBearerToken') || getExtensionModeInfo().enabled;
  return useQuery<Authentication>([path], {
    enabled,
    staleTime: TWELVE_HOURS,
    cacheTime: TWELVE_HOURS,
  });
}

interface useChangeLocationArgs {
  locationId: string;
  routePath?: string;
}
export function useChangeLocation() {
  const client = useQueryClient();
  const { mutateAsync: switchRooftop } = useSwitchRooftopMutation();
  return useMutation(
    ({ locationId, routePath }: useChangeLocationArgs) =>
      new Promise(async (resolve) => {
        const user = await defaultMutationFn(
          `/authenticate/locations/${locationId}`,
          'POST'
        );
        saveCredentials({
          authBearerToken: user.token,
          authBearerTokenExpiration: user.expirationDate,
          authRefreshToken: user.refreshToken,
        });

        client.setQueryData(['/authenticate/token'], user, {
          updatedAt: Date.now(),
        });

        if (routePath) {
          navigate(routePath);
        }

        await switchRooftop();

        resolve(user);
      })
  );
}

export function useLocations() {
  const path = '/authenticate/locations';
  return useQuery<Location[]>([path]);
}

export function useParentLocation() {
  const path = '/authenticate/parentLocation';
  return useQuery<Location>([path]);
}

export function useCurrentLocation() {
  const { data } = useToken();
  return data?.location;
}

export async function fetchAuthCognito() {
  const host = process.env.REACT_APP_RECONVELOCITY_APPSERVICE_URL_OVERRIDE;
  const path = `/oauth2/authorization/cognito?redirect=${window?.location?.origin}/sso`;
  const res = await fetch(`${host}${path}`);
  const json = await res.json();
  return json;
}

export function useCallbackCognito(locationSearch: string) {
  const host = process.env.REACT_APP_RECONVELOCITY_APPSERVICE_URL_OVERRIDE;
  const path = `/oauth2/callback/cognito${locationSearch}`;
  return useQuery(
    ['/authenticate/token'],
    async () => {
      try {
        const res = await fetch(`${host}${path}`);
        return res.json();
      } catch (error) {
        console.error('Error logging into SSO', error);
      }
    },
    {
      enabled: !!locationSearch,
      onSuccess: (user) => {
        saveCredentials({
          authBearerToken: user?.token,
          authBearerTokenExpiration: user?.expirationDate,
          authRefreshToken: user?.refreshToken,
        });
      },
    }
  );
}

import { useQuery } from '@tanstack/react-query';

import { APIResponse } from 'api';
import { Prospect } from 'models';

/**
 * @name useProspect
 *
 * @param {string} id - prospectID as UUID
 * @returns {Prospect}
 *
 * @example
 *
 * const prospectId = '11eb85cc-dce3-e936-9d07-0ac9483cdf87'
 * const { data, isLoading } = useProspect(prospectId)
 * return data?.contact?.fullName || prospectId
 */

export function useProspect(id?: string) {
  const path = `/plugins/vdp/velocityengage/prospects/${id}`;
  return useQuery<APIResponse<Prospect>>([path], {
    enabled: id !== undefined,
  });
}

export default useProspect;

import { OutlinedInputProps, TextField } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';

import { useVendor, useWindowSize } from 'hooks';
import { Invoice as InvoiceType } from 'models/invoicing';

import DesktopInvoice from './DesktopInvoice';
import MobileInvoice from './MobileInvoice';

import './Invoice.scss';

type Props = {
  invoice?: InvoiceType;
  onInvoiceNumberChange?: OutlinedInputProps['onChange'];
} & RouteComponentProps;

const Invoice = ({ invoice, onInvoiceNumberChange }: Props) => {
  const { isVendor } = useVendor();
  const { isMobileViewport } = useWindowSize();
  let total = 0;
  const vehicle = invoice?.vehicle!;
  const invoiceStatus = invoice?.vehicle?.tasks?.[0]?.status;

  let statusComponent = null;
  // Only show invoiced status if user is vendor
  if (isVendor && invoice?.invoiceNumber && invoiceStatus === 'INVOICED') {
    statusComponent = <div className="invoice-status invoiced">PENDING</div>;
  }
  if (invoiceStatus === 'PAID') {
    statusComponent = <div className="invoice-status paid">PAID</div>;
  }

  const isMobile = isMobileViewport();
  const invoiceNumber = invoice?.invoiceNumber ? (
    <div className="invoice-number-container">
      <div className="invoice-number-label">
        {isMobile ? 'Invoice #' : 'Invoice Number'}
      </div>
      <div className="invoice-number">{invoice?.invoiceNumber}</div>
    </div>
  ) : (
    <TextField
      className="invoice-number-input"
      onChange={onInvoiceNumberChange}
      required
      variant="outlined"
      size="small"
      placeholder="Invoice Number"
      autoFocus
      inputProps={{ maxLength: 30 }}
    />
  );

  const vehicleTasksTotal =
    vehicle?.tasks?.reduce((acc, task) => {
      total += task?.price?.amount;
      return (acc += task?.price?.amount ?? 0);
    }, 0) ?? 0;

  if (!invoice) {
    return null;
  }

  return isMobile ? (
    <MobileInvoice
      invoice={invoice}
      invoiceNumber={invoiceNumber}
      statusComponent={statusComponent}
      total={total}
      vehicleTasksTotal={vehicleTasksTotal}
    />
  ) : (
    <DesktopInvoice
      invoice={invoice}
      invoiceNumber={invoiceNumber}
      statusComponent={statusComponent}
      total={total}
      vehicleTasksTotal={vehicleTasksTotal}
    />
  );
};

export default Invoice;
